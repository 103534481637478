import iconFioul from "../assets/icons/fioul.png";
import iconElec2 from "../assets/icons/elec.png";
import iconGaz from "../assets/icons/gaz.png";
import L from "leaflet";

export const iconEnergies: { [key: number]: string } = {
    12: iconFioul,
    4: iconFioul,
    9: iconFioul,
    3: iconFioul,
    1: iconElec2,
    2: iconGaz
}

export const colorEnergies: { [key: number]: string } = {
    15: "#C7EAFF", // réseau de froid urbain
    14: "#979797", // autre combustible fossile
    13: "#0A73B0", // gpl
    12: "#B3F270", // électricité d'origine renouvelable utilisée dans le bâtiment
    11: "#313D4F", // charbon
    10: "#92B3F5", // butane
    9: "#0755F2", // propane
    8: "#14833A", // réseau de chauffage urbain
    7: "#C49570", // bois – plaquettes d’industrie
    6: "#FEAC68", // bois – plaquettes forestières
    5: "#DE7D2E", // bois – granulés (pellets) ou briquettes
    4: "#873F04", // bois – bûches
    3: "#7C90AA", // fioul domestique
    2: "#00A1FF", // gaz naturel
    1: "#5FBB46", // électricité
};

export const colorDPE: { [key: string]: string } = {
    A: "#14833A",
    B: "#27C63F",
    C: "#ADE333",
    D: "#EBE609",
    E: "#FDA71B",
    F: "#FB6614",
    G: "#DC0404"
}

export const customMarkerIcon = (letter: string) => {
    const backgroundColor = colorDPE[letter]; // Récupère la couleur depuis colorDPE

    return L.divIcon({
        html: `
            <div 
                class="custom-marker" 
                data-color="${backgroundColor}" 
                style="background-color: ${backgroundColor}; --marker-after-color: ${backgroundColor};"
                >
                <span class="marker-letter">${letter}</span>
            </div>
        `,
        className: 'custom-div-icon', // Classe pour personnaliser le style
        iconSize: [25, 25],
        iconAnchor: [12, 12],
    });
};