import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import {useMutation} from "react-query";
import {resetPassword} from "../../../api/auth";
import WModalConfirmation from "../../../components/WModalConfirmation";

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const {token} = useParams<{ token: string }>();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {mutate, isLoading} = useMutation(
        async ({token, newPassword}: { token: string; newPassword: string }) => {
            return await resetPassword(token, newPassword);
        },
        {
            onSuccess: () => {
                setModalOpen(true);
            },
            onError: () => {
                setErrorMessage("Une erreur s'est produite. Veuillez réessayer.");
            },
        }
    );

    const handleResetPassword = () => {
        if (newPassword !== confirmPassword) {
            setErrorMessage("Les mots de passe ne correspondent pas.");
            return;
        }
        if (!token) {
            setErrorMessage("Token invalide ou manquant.");
            return;
        }
        mutate({token, newPassword});
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        navigate('/login');
    };

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <span className="font-bold text-lg mt-8">Réinitialisez votre mot de passe</span>
            <span className="font-normal mb-6">
                Veuillez entrer votre nouveau mot de passe ci-dessous.
            </span>

            <div className="bg-white rounded-[10px] shadow mt-6 w-full max-w-[600px] flex flex-col p-6 md:p-12">
                {/* New Password */}
                <div className="flex gap-6 w-full mt-6">
                    <WInput
                        placeholder="Nouveau mot de passe"
                        name="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full"
                        required
                    />
                </div>
                {/* Confirm Password */}
                <div className="flex gap-6 w-full mt-6">
                    <WInput
                        placeholder="Confirmez le mot de passe"
                        name="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full"
                        required
                    />
                </div>
                {/* Error Message */}
                {errorMessage && (
                    <div className="text-red-500 text-sm mt-4">
                        {errorMessage}
                    </div>
                )}
                {/* Submit Button */}
                <div className="flex m-auto mt-8">
                    <WButton
                        variant="secondary"
                        className="btn-form"
                        onClick={handleResetPassword}
                        disabled={isLoading}
                    >
                        {isLoading ? "Envoi..." : "Réinitialiser"}
                    </WButton>
                </div>
            </div>

            <WModalConfirmation
                isOpen={isModalOpen}
                title={`Mot de passe réinitialisé`}
                message={
                    <div>
                        <p>Votre mot de passe a été réinitialisé avec succès.</p>
                        <p>Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>
                    </div>
                }
                footer={<p>Vous serez redirigé vers la page de connexion.</p>}
                onClose={handleCloseModal}
                onConfirm={handleCloseModal}
                titleConfirme="Ok, compris"
                confirmButtonClassName="!bg-green-500"
            />
        </div>
    );
};

export default ResetPassword;
