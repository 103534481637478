import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"; // Ajout pour redirection
import "./styles.css";
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { forgotPassword } from "../../../api/auth";
import WModalConfirmation from "../../../components/WModalConfirmation";

const ForgotPassword: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [isModalOpen, setModalOpen] = useState(false); // État pour ouvrir/fermer la modale
    const navigate = useNavigate(); // Hook pour redirection

    const { mutate } = useMutation(async (email: string) => {
        return await forgotPassword(email);
    }, {
        onSuccess: () => {
            setModalOpen(true); // Ouvrir la modale en cas de succès
        },
    });

    const handleSetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSendResetLink = () => {
        mutate(email);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        navigate("/"); // Redirige vers la page d'accueil
    };

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <span className="font-bold text-lg mt-8">Mot de passe oublié ?</span>
            <span className="font-normal">
                Renseignez l’email de votre compte et nous vous enverrons un lien pour<br />
                créer un nouveau mot de passe
            </span>

            <div className="fp-form-container flex flex-col w-svw justify-center items-center max-w-[500px] m-auto">
                {/* Email */}
                <div className="flex gap-6 w-full">
                    <WInput
                        placeholder={t('Email')}
                        name="email"
                        type="email"
                        value={email}
                        onChange={handleSetEmail}
                        className="w-full"
                        required
                    />
                </div>

                <div className="flex m-auto mt-8">
                    <WButton
                        variant="secondary"
                        className="btn-form"
                        onClick={handleSendResetLink}
                    >
                        Envoyer
                    </WButton>
                </div>
            </div>

            <div className="flex justify-center space-x-4 mt-3">
                <a href="/login" className="text-gray-400 text-sm font-medium underline mr-10">
                    J’ai déjà un compte
                </a>
                <a href="/login" className="text-green-600 text-sm font-medium underline ml-10">
                    Je me connecte
                </a>
            </div>

            <WModalConfirmation
                isOpen={isModalOpen}
                title={`Lien envoyé !`}
                message={<div>Un lien de réinitialisation de mot de passe a été envoyé à l&apos;adresse  <span className="font-bold">{email}</span>. Consultez votre boîte mail pour continuer.</div>}
                footer={`Vous serez redirigé vers la page d'accueil après confirmation.`}
                onConfirm={handleCloseModal}
                titleConfirme="Ok, compris"
                confirmButtonClassName="!bg-green-500"
             />
        </div>
    );
};

export default ForgotPassword;
