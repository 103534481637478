import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import WPrice from "../../../components/WPrice";
import WSwitch from "../../../components/WSwitch";
import './styles.css';
import {useQuery} from "react-query";
import {getPlan} from "../../../api/payment";

const PricingPlans: React.FC = () => {
    const {t} = useTranslation();
    const [year, setYear] = useState<boolean>(true);

    const {data: planPrice} = useQuery(['getPlanPrice'], async () => {
        return await getPlan();
    });

    return (
        <div className="flex flex-col w-svw">
            <div className="font-bold text-lg mt-3">{t('welcome')}</div>
            <div className="font-normal">{t('subscription')}</div>

            <div className="abo">
                <span className="period">Mensuel</span>
                <WSwitch onChange={setYear} />
                <span className="period"><span>Annuel</span><span className="reduc">(-20%)</span></span>
            </div>

            <div className="flex flex-row flex-wrap justify-center mb-10">
                {planPrice && planPrice.offresDuMoment.map((elm) => {
                    return <div key={elm.id} className="m-2 mt-8">
                        <WPrice elm={elm} year={!year} />
                    </div>
                })}
            </div>

            <div>
                <a href="https://wattless.fr/"
                   target="_blank"
                   rel="noreferrer"
                   className="border-2 border-[#7C90AA] p-3 text-[#7C90AA] font-[600] rounded-lg hover:text-white focus:ring-gray-600 hover:bg-[#7C90AA]">Revenir au site Web</a>
            </div>
        </div>
    );
};

export default PricingPlans;
