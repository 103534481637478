import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectProjectById, updateProject as updateProjectRedux} from "../../../redux/slices/projectSlice";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import WButton from "../../../components/Wbutton";
import {useParams} from "react-router-dom";
import {generalInformation, loiClimatResilience, updateProject} from "../../../api/project";
import {useMutation, useQuery} from "react-query";
import dpeA from "../../../assets/icons/DPE_GRAY/DPE A.svg";
import dpeB from "../../../assets/icons/DPE_GRAY/DPE B.svg";
import dpeC from "../../../assets/icons/DPE_GRAY/DPE C.svg";
import dpeD from "../../../assets/icons/DPE_GRAY/DPE D.svg";
import dpeE from "../../../assets/icons/DPE_GRAY/DPE E.svg";
import dpeF from "../../../assets/icons/DPE_GRAY/DPE F.svg";
import dpeG from "../../../assets/icons/DPE_GRAY/DPE G.svg";

import { PencilIcon } from "@heroicons/react/16/solid";
import iconCube from "../../../assets/icons/Cube.png";
import iconElec from "../../../assets/icons/electricity.png";
import iconFolder from "../../../assets/icons/folder.png";
import iconProfil from "../../../assets/icons/profil.png";
import iconInfo from "../../../assets/icons/info.png";

import {B, Structure,} from "../../../api/interfaces/ApiInterfaces";
import WLoader from "../../../components/WLoader/index.";
import {Project, Status} from "../../../api/interfaces/ProjectInterfaces";
import Menu from "../menu";
import ApportFree from "../Action/ApportFree";
import EcoTypeEnergies from "../Action/EcoTypeEnergies";
import RepartitionUsage from "../Action/RepartitionUsage";
import Deperdition from "../Action/Deperdition";
import {useTranslation} from "react-i18next";
import {colorDPE, customMarkerIcon} from "../../../constants";
import WToast from "../../../components/WToast";

const colorGesDPE: { [key: string]: string } = {
    A: "#A4DBF8",
    B: "#8CB4D3",
    C: "#7692B2",
    D: "#5F6E8E",
    E: "#4D5170",
    F: "#393652",
    G: "#271A35"
}
// Fonction pour convertir hex en rgba avec 10% d'opacité
const hexToRgba = (hex: string, opacity: number) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
const getColorLoi = (loiStatus: Status, opacity: number) => {
    switch (loiStatus) {
        case Status.DANGER:
            return hexToRgba(colorDPE["G"], opacity); // Couleur pour danger
        case Status.WARNING:
            return hexToRgba(colorDPE["E"], opacity); // Couleur pour warning
        case Status.SUCCESS:
            return hexToRgba(colorDPE["A"], opacity); // Couleur pour succès
    }
}
const getValue = (value: string | number | B | undefined) => {
    if (value === undefined) {
        return "";
    }

    return (typeof value === "string" || typeof value === "number") ?
        value : value.donnee;
}

interface ElementsProps {
    elements: Structure[];
}

const Accordion: React.FC<ElementsProps> = ({elements}) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    // Fonction pour gérer l'ouverture et la fermeture des éléments
    const handleAccordionClick = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="rounded-lg flex-1">
            {elements.map((element, index) => (
                <div key={index} className={`border-b border-gray-300 
                ${index === 0 ? 'rounded-lg' : ''}  
                ${index === elements.length - 1 ? 'rounded-b-2xl' : ''}
                `}>
                    <button
                        className={`w-full text-left p-4 font-medium h-16
                        ${index === 0 ? 'rounded-t-2xl' : ''}
                        ${index === elements.length - 1 ? 'rounded-b-2xl' : ''}
                        ${activeIndex === index ? 'bg-[#5FBB46] text-white' : 'bg-[#E7EEF8] text-black'}`}
                        onClick={() => handleAccordionClick(index)}
                    >
                        {getValue(element.blocTitle)}
                    </button>

                    <div className="justify-center">
                        {activeIndex === index &&
                            <table className="table-auto text-left w-full bg-gray-100">
                                {Object.keys(element).map((k, i) => {
                                    return <tr
                                        key={k}
                                        className={`${i % 2 === 0 ? 'bg-gray-100' : 'bg-white'} p-4 border-t`}>
                                        <td className="font-bold p-3">{k}</td>
                                        <td>{getValue(element[k])}</td>
                                    </tr>
                                })}
                            </table>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

const SingleInfo: React.FC = () => {

    /* Récuperation ID param */
    const {id} = useParams<{ id: string }>();
    const numericId = id ? parseInt(id) : null;
    const currentProject = useSelector(selectProjectById(numericId));
    const dispatch = useDispatch();

    const [libelleTemp, setLibelleTemp] = useState(currentProject?.libelle || "");
    const [isEditing, setIsEditing] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [coordinates, setCoordinates] = useState<{ lat: number, lng: number } | null>(null);
    const [activeDiv, setActiveDiv] = useState(1);
    const {t} = useTranslation();
    const {data: generalInformationData} = useQuery(
        ["projectInfo", numericId],
        async () => {
            return (numericId) ? await generalInformation(numericId) : null;
        }
    );

    const {mutate: mutateUpdateProject} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess: () => {
            setShowToast(true);
        }
    });

    const handleSave = () => {
        if (currentProject && libelleTemp !== currentProject.libelle) {
            const newProject = { ...currentProject, libelle: libelleTemp };
            mutateUpdateProject({ project: newProject });
            dispatch(updateProjectRedux(newProject));
        }
        setIsEditing(false); // Sortie du mode édition
    };

    if (!currentProject) {
        return <WLoader message={"Chargement"} />;
    }

    const {data: loiClimatResilienceData} = useQuery(
        ["loiClimatResilience", numericId],
        async () => {
            return (numericId) ? await loiClimatResilience(numericId) : null;
        }
    );

    useEffect(() => {
        if (currentProject?.adresse) {
            const fullAddress = `${currentProject.adresse.adresse}, ${currentProject.adresse.ville}, ${currentProject.adresse.codePostal}`;
            fetchCoordinatesFromAddress(fullAddress).then();
        }
    }, [currentProject]);

    const handleButtonClick = (divIndex: number) => {
        setActiveDiv(divIndex);
    };

    const fetchCoordinatesFromAddress = async (address: string) => {
        const provider = new OpenStreetMapProvider();
        try {
            const results = await provider.search({query: address});
            if (results.length > 0) {
                const {x: lng, y: lat} = results[0];
                setCoordinates({lat, lng});
                console.log({lat, lng});
            } else {
                console.error("Adresse introuvable:", address);
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des coordonnées:", error);
        }
    };

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    if (!currentProject || !generalInformationData) {
        return <WLoader message={"Chargement"}/>;
    }

    return <div className="w-full max-w-[1200px]">
        <div className="w-full flex justify-between mt-6">
            <Menu projectId={currentProject.id}/>
        </div>

        <div className="w-full text-left font-bold text-xl mt-12 ml-2"><span><img src={iconFolder}
                                                                                  className="inline mr-3"/></span> Informations
            générales
        </div>
        <div className="flex flex-row flex-wrap items-stretch bg-white rounded-[10px] shadow-md mt-2 m-2">
            <div className="flex flex-col order-last sm:order-first min-w-[312px] flex-1 p-4">
                <div className="text-left bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 relative flex items-center">
                    <img src={iconInfo} alt="info" className="inline mx-3 h-full"/>
                    <span className="font-bold">Nom du projet :</span>
                    {isEditing ? (
                        <input
                            type="text"
                            value={libelleTemp}
                            onChange={(e) => setLibelleTemp(e.target.value)}
                            onBlur={handleSave}
                            className="inline-block ml-2 p-1 border border-gray-300 rounded"
                            autoFocus
                        />
                    ) : (
                        <div className="flex items-center">
                            <span className="font-bold text-green-600 underline ml-2">{currentProject.libelle}</span>
                            <div
                                className="absolute right-3 ml-2 p-1 rounded-full hover:bg-gray-300 cursor-pointer flex items-center justify-center"
                                onClick={() => setIsEditing(true)}
                            >
                                <PencilIcon className="h-5 w-5 text-gray-600" />
                            </div>
                        </div>
                    )}

                </div>
                <div className="text-left bg-[#E7EEF8] mb-2 border-[#7C90AA] border h-14 leading-[3.5rem]">
                    <img src={iconProfil} alt="profil" className="inline mx-3 h-[33px]"/>
                    <span className="font-bold">Gestionnaire :</span>
                    <span
                        className={`font-bold underline ${currentProject.gestionnaire ? 'text-green-600' : 'text-red-600'}`}> {currentProject.gestionnaire ? (currentProject.gestionnaire.nom + ' ' + currentProject.gestionnaire.prenom) : 'Aucun Gestionnaire'}</span>
                </div>

                <table className="table-auto text-left w-full">
                    <tbody>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">N° Ademe :</td>
                        <td className="p-3">{currentProject?.numeroAdeme}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Adresse :</td>
                        <td className="p-3">{currentProject?.adresse.adresse}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Code postal :</td>
                        <td className="p-3">{currentProject?.adresse.codePostal}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Ville :</td>
                        <td className="p-3">{currentProject?.adresse.ville}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Typologie :</td>
                        <td className="p-3">{currentProject?.adresse.type}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Surface habitable :</td>
                        <td className="p-3">{currentProject?.adresse.surfaceHabitable} m²</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Altitude :</td>
                        <td className="p-3">{currentProject?.adresse.altitude}</td>
                    </tr>
                    <tr>
                        <td className="p-3 font-bold">Zone climatique :</td>
                        <td className="p-3">{currentProject?.adresse.zoneClimatique}</td>
                    </tr>
                    <tr className="bg-gray-100">
                        <td className="p-3 font-bold">Énergies :</td>
                        <td className="p-3">{currentProject?.adresse.energies}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
            <div className="flex min-w-[312px] min-h-[250px] flex-1">
                {coordinates && (
                    <div className="h-full w-full mb-6">
                        <MapContainer
                            center={coordinates}
                            zoom={25}
                            style={{height: '100%', width: '100%'}}
                            className="w-full rounded-br-[10px] rounded-tr-[10px]"
                        >
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                            <Marker position={coordinates}
                                    icon={customMarkerIcon(generalInformationData.infoGenerales.etiquetteDPE)}>
                                <Popup>{currentProject?.adresse.adresse}</Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                )}
            </div>
        </div>

        <div className="text-left font-bold text-xl mt-12 ml-2"><span><img src={iconElec}
                                                                           className="inline mr-3"/></span> Informations
            énergétiques
        </div>
        <div className="flex flex-row flex-wrap items-stretch">
            <div className="flex flex-col flex-1">
                <div className="flex flex-wrap items-stretch">
                    <div
                        className="flex flex-1 flex-col min-w-[250px] shadow-md p-3 text-white rounded-[10px] m-2 text-center justify-center"
                        style={{backgroundColor: colorDPE[generalInformationData?.infoGenerales.etiquetteConso]}}
                    >
                        Consommation
                        <span
                            className="text-[4rem] leading-normal">{generalInformationData?.infoGenerales.etiquetteConso}</span>
                        <span className="font-bold">Passoire thermique</span>
                        DPE Actuel
                    </div>

                    <div
                        className="flex flex-1 flex-col min-w-[250px] shadow-md p-3 text-white bg-[#9932CD] rounded-[10px] m-2 text-center justify-center"
                        style={{backgroundColor: colorGesDPE[generalInformationData?.infoGenerales.etiquetteGES]}}
                    >
                        Gaz à Effet de Serre (GES)
                        <span
                            className="text-[4rem] leading-normal">{generalInformationData?.infoGenerales.etiquetteGES}</span>
                        <span className="font-bold">Passoire thermique</span>
                        DPE Actuel
                    </div>
                </div>
                <div className="flex flex-wrap items-stretch">
                    <ApportFree apportInterne={parseInt(generalInformationData?.infoGenerales.apportInterne ?? 0)}
                                apportSolaire={parseInt(generalInformationData?.infoGenerales.apportSolaire ?? 0)}
                    />
                    <div
                        className="w-1/2 flex flex-row flex-1 min-w-[250px] items-center shadow-md p-3 text-white bg-[#00257b99] rounded-[10px] m-2 text-center justify-center"
                        style={{backgroundColor: colorDPE[currentProject?.classeEnergetiqueObjectif]}}
                    >
                        <div className="w-1/2"><img
                            src={dpeImages[currentProject?.classeEnergetiqueObjectif as string]}
                            alt={currentProject?.classeEnergetiqueObjectif} className="h-40 m-3"/></div>
                        <div className="flex flex-col text-center items-center w-1/2">
                            <span className="font-bold">Objectif DPE</span>
                            <span
                                className="text-[4rem] leading-normal">{currentProject?.classeEnergetiqueObjectif}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                <div
                    className="flex flex-col shadow-md p-5 border-[#FF0000] bg-[#FF00001A] border-2 border-dotted items-center rounded-[10px] m-2 text-center justify-center h-1/2"
                    style={{
                        backgroundColor: getColorLoi(loiClimatResilienceData?.status || Status.DANGER, 0.1),
                        borderColor: getColorLoi(loiClimatResilienceData?.status || Status.DANGER, 1),
                    }}>

                    <span className="mb-10">{loiClimatResilienceData?.description}</span>
                    <span>{loiClimatResilienceData?.action}</span>
                </div>
                <EcoTypeEnergies repartitionParEnergie={generalInformationData.repartitionParEnergie}/>
            </div>
        </div>

        <div className="flex flex-row flex-wrap items-stretch">
            <Deperdition deperditionEnergetique={generalInformationData.deperditionEnergetique}/>
            <RepartitionUsage repartitionUsageParEnergie={generalInformationData.repartitionUsageParEnergie}/>
        </div>
        <div className="w-full text-left font-bold text-xl mt-12 ml-2"><span>
            <img src={iconCube} className="inline mr-3"/></span>Informations structurelles
        </div>
        <div className="flex flex-col lg:flex-row flex-wrap items-stretch">
            <div className="flex flex-col lg:flex-row flex-1 m-2 bg-white rounded-[10px] shadow-md">

                {/* Section des boutons */}
                <div className="p-5 flex flex-wrap lg:flex-col lg:w-auto w-full justify-center lg:justify-start">
                    {Object.keys(generalInformationData.mapInformationsStructurelles).sort().map((key, index) =>
                        <WButton
                            key={key}
                            variant={activeDiv === index ? "secondary" : "primary"}
                            onClick={() => handleButtonClick(index)}
                            className="w-full lg:w-aut mb-2">{t(key.substring(key.indexOf("_") + 1, key.length))}
                        </WButton>
                    )}
                </div>

                {/* Div de contenu qui change selon le bouton cliqué */}
                <div className="flex flex-1 p-5 w-full lg:min-w-[800px]">
                    {Object.keys(generalInformationData.mapInformationsStructurelles).sort().map((key, index) =>
                        activeDiv === index ? <div key={key} className="flex flex-1">
                            <Accordion elements={generalInformationData.mapInformationsStructurelles[key]}/>
                        </div> : null
                    )}
                </div>
            </div>
        </div>
        {showToast && (
            <WToast
                message="Modification effectué avec succès"
                type="success"
                onClose={() => setShowToast(false)} // Masque le toast après fermeture
            />
        )}
    </div>;
};


export default SingleInfo;