import React from 'react';
import './styles.css';

interface ModalProps {
    isOpen: boolean;
    title: React.ReactNode;
    message: React.ReactNode;
    footer: React.ReactNode;
    titleConfirme: React.ReactNode; // Accepte du texte ou du JSX
    titleCancel?: React.ReactNode;  // Accepte du texte ou du JSX
    confirmButtonClassName?: string; // Classes supplémentaires pour le bouton de confirmation
    cancelButtonClassName?: string;  // Classes supplémentaires pour le bouton d'annulation
    onConfirm: () => void;
    onCancel?: () => void;
    onClose?: () => void;
}

const WModalConfirmation: React.FC<ModalProps> = ({
                                                      isOpen,
                                                      title,
                                                      message,
                                                      footer,
                                                      titleConfirme,
                                                      titleCancel,
                                                      confirmButtonClassName = '', // Valeurs par défaut
                                                      cancelButtonClassName = '',
                                                      onConfirm,
                                                      onCancel,
                                                      onClose,
                                                  }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
            <div className="bg-white rounded-lg w-1/2 p-8 shadow-lg">
                <p className="text-left text-lg font-semibold mb-6">{title}</p>
                <p className="text-left text-lg mb-6">{message}</p>
                <p className="text-left text-lg mb-6">{footer}</p>
                <div className="flex justify-around">
                    <button
                        onClick={() => {
                            onConfirm();
                            onClose && onClose();
                        }}
                        className={`px-3 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 ${confirmButtonClassName}`}
                    >
                        {titleConfirme}
                    </button>
                    {onCancel && <button
                        onClick={() => {
                            onCancel && onCancel();
                            onClose && onClose();
                        }}
                        className={`px-3 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ${cancelButtonClassName}`}
                    >
                        {titleCancel}
                    </button>}
                </div>
            </div>
        </div>
    );
};

export default WModalConfirmation;
