import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Project, Address} from "../../api/interfaces/ProjectInterfaces";

// Define the slice state for projects
interface IProjectState {
    projects: Project[]; // List of projects
    selectedProjectId: number | null; // Currently selected project, if any
}

// Define the initial state using that type
const initialState: IProjectState = {
    projects: [],
    selectedProjectId: null,
};

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        initProjects(state, action: PayloadAction<Project[]>) {
            state.projects = action.payload
            state.selectedProjectId = null;
        },
        // Action to handle adding a new project to the state
        addProject(state, action: PayloadAction<Project>) {
            state.projects.push(action.payload);
            state.selectedProjectId = action.payload.id;
        },
        // Action to handle setting the selected project
        setSelectedProject(state, action: PayloadAction<number | null>) {
            state.selectedProjectId = action.payload;
        },
        // Action to remove a project from the state by its ID
        removeProject(state, action: PayloadAction<number>) {
            state.projects = state.projects.filter(
                (project) => project.id !== action.payload
            );
            state.selectedProjectId = null;
        },
        updateProject(state, action: PayloadAction<Project>) {
            const updatedProject = action.payload;
            const projectIndex = state.projects.findIndex(project => project.id === updatedProject.id);
            if (projectIndex !== -1) {
                state.projects[projectIndex] = {
                    ...state.projects[projectIndex],
                    ...updatedProject, // Mises à jour des champs modifiés
                };
            }
        },
        removeCurrentProject(state) {
            state.projects = state.projects.filter(
                (project) => project.id !== state.selectedProjectId
            );
            state.selectedProjectId = null;
        },
        // Action to clear the selected project
        clearSelectedProject(state) {
            state.selectedProjectId = null;
        },
        setValidation(state, action: PayloadAction<{ projectId: number; validation: Address }>) {
            const project = state.projects.find(p => p.id === action.payload.projectId);
            if (project) {
                project.adresse = action.payload.validation; // Met à jour la validation du projet
            }
        },
        reset() {
            return initialState;
        }
    },
});

// Sélecteur pour obtenir le projet actuel
export const selectCurrentProject = (state: { project: IProjectState }) => {
    if (state.project.selectedProjectId === null) return null;

    return state.project.projects.find(project => project.id === state.project.selectedProjectId);
};

export const selectAllProjects = (state: { project: IProjectState }) => {
    return state.project.projects;
};

export const selectProjectById = (id: number | null) => (state: { project: IProjectState }) => {
    return id !== null ? state.project.projects.find(project => project.id === id) || null : null;
};

// Export actions
export const {
    initProjects,
    addProject,
    setValidation,
    updateProject,
    reset,
    setSelectedProject,
    removeProject,
    removeCurrentProject,
    clearSelectedProject } = projectSlice.actions;

// Export reducer
export default projectSlice.reducer;
