import axios from 'axios';
import { store } from '../store';

const API_URL = process.env.REACT_APP_API_PROJECT_URL;

// Configurer Axios
const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Ajouter un intercepteur pour injecter le token dans chaque requête
axiosInstance.interceptors.request.use(
    (config) => {
        const token = store.getState().auth.token;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        window.location.href = '/login'; // Rediriger vers la page de connexion

        return Promise.reject(error);
    }
);

export default axiosInstance;
