import React from "react";

interface ApportFreeProps {
    apportInterne: number;
    apportSolaire: number;
    vsApportInterne?: number;
    vsApportSolaire?: number;
}

const ApportFree: React.FC<ApportFreeProps> = ({apportInterne, apportSolaire, vsApportInterne, vsApportSolaire}) => {

    return <div
        className="m-2 flex flex-col flex-1 min-w-[250px] bg-white rounded-[10px] text-center justify-center w-1/2">
        <div className="justify-between flex flex-col h-full p-6">
            <div className="text-left font-bold text-xl">Apports gratuits</div>
            <div
                className="text-[#7C90AA] font-bold flex-1 flex flex-col justify-center text-left text-nowrap">
                <div>interne :
                    <span className={`${!vsApportInterne ? "text-[#5FBB46]" : ""}`}> {apportInterne.toFixed(1)} kWh</span>
                    {vsApportInterne && <span className="text-[#5FBB46]"> vs {vsApportInterne.toFixed(1)} kWh</span>}
                </div>
                <div>solaire :
                    <span className={`${!vsApportSolaire ? "text-[#5FBB46]" : ""}`}> {apportSolaire.toFixed(1)} kWh</span>
                    {vsApportSolaire && <span className="text-[#5FBB46]"> vs {vsApportSolaire.toFixed(1)} kWh</span>}
                </div>
            </div>
        </div>
    </div>
};

export default ApportFree;