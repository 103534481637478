import React, {useState} from 'react';
import iconObj from "../../../assets/icons/actions-plan2.svg";
import iconWind from "../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../../assets/icons/icon optim/chauffage.svg";
import iconProject from "../../../assets/icons/icon optim/iso-ext.svg";

import {useDispatch, useSelector} from "react-redux";
import {selectProjectById, updateProject as updateProjectRedux} from "../../../redux/slices/projectSlice";
import {useNavigate, useParams} from "react-router-dom";
import WObjective from "../../../components/WObjective";
import {useMutation, useQuery} from "react-query";
import {getPlanAction, updateProject} from "../../../api/project";
import {Project} from "../../../api/interfaces/ProjectInterfaces";
import WDPESelector from "../../../components/WDPESelector";
import WToast from "../../../components/WToast";
import Menu from "../menu";
import WLoader from "../../../components/WLoader/index.";
import ObjectifInfo from "./ObjectifInfo";

const PlanAction: React.FC = () => {

    /* Récuperation ID param */
    const {id} = useParams<{ id: string }>();
    const numericId = id ? parseInt(id) : null;
    const currentProject = useSelector(selectProjectById(numericId));

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classeActuelle = currentProject?.classeEnergetiqueActuel || 'G'; // Valeur par défaut
    const [showToast, setShowToast] = useState(false);

    const {mutate: select} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess : () => {
            setShowToast(true);
        }
    });

    const {data: planActionData} = useQuery(['planAction'], async () => {
        if (!numericId) return null;
        return await getPlanAction(numericId);
    }, {enabled: !!numericId});

    const action = planActionData && planActionData.find(plan => plan.classeEnergetiqueObjectique === currentProject?.classeEnergetiqueObjectif);

    const handleDetail = () => {
        navigate(`/project/${numericId}/detail/${action?.id}`);
    }

    const handleSelect = (objective: string) => {
        if (currentProject) {
            const newProject = {
                ...currentProject,
                classeEnergetiqueObjectif: objective
            };

            select({
                project: newProject
            });

            dispatch(updateProjectRedux(newProject));

        }

    };

    if (!currentProject) {
        return <WLoader message="Recherche du projet" />;
    }

    return (
        <div className="w-full max-w-[1200px]">
            <div className="w-full flex justify-between mt-6">
                <Menu projectId={currentProject.id} />
            </div>
            <div className="text-left mb-6">
                <div className="flex mt-12 mx-6">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3">
                        <div className="font-bold text-lg  ml-3 mr-3">Plans d&apos;action</div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                    <div className="flex flex-col p-6 md:p-12">
                        {numericId && <ObjectifInfo numericId={numericId} classeActuelle={classeActuelle} />}
                        <p className="text-lg font-bold mt-12">Votre Objectif</p>
                        <div onClick={handleDetail}
                            className="cursor-pointer shadow-md border rounded-lg w-full py-6 px-12 mt-3 flex flex-row justify-start items-start">
                            <div className="flex flex-row flex-wrap items-stretch">
                                <div className="flex flex-1 flex-col mr-6 min-w-[300px] mt-8">
                                    <div className="mb-6">
                                        <WObjective level={currentProject.classeEnergetiqueActuel}
                                                    objective={currentProject.classeEnergetiqueObjectif}/>
                                    </div>
                                    <div className="">Avec un
                                        DPE G vous êtes aux normes au delà de
                                        2034
                                        Pour atteindre votre objectif vous devez économiser minimum
                                        <div className="relative font-bold block mt-3">{currentProject.economieEnergie} kWhep / An</div>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col mr-6 min-w-[300px] mt-8">
                                    <div className="font-bold text-lg">Travaux à prévoir :</div>

                                    {action?.travaux.map((trav, index) => {

                                        const iconMap: Record<string, string> = {
                                            "Isolation intérieurs murs exposés": iconProject,
                                            "Isolation extérieure murs exposés": iconProject,
                                            "Pose double vitrage": iconWind,
                                            "Ventillation": iconVent,
                                            "Chauffage": iconChauf,
                                        };

                                        return (
                                            <React.Fragment key={index}>
                                                <div
                                                    className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                                <div className="flex space-x-4">
                                                    <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                         alt="Icon"/>
                                                    <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                                <div className="flex flex-1 flex-col min-w-[300px] mt-8">
                                    <div className="font-bold text-lg">Estimations :</div>
                                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                    <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                                        className="font-bold"> {action?.surfacePerdue} m²</span></p>
                                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                    <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie :</p>
                                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.economieEnergie}</p>
                                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.budget}</p>
                                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.montantAides}</p>
                                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action?.resteACharge}</p>


                                </div>
                            </div>
                        </div>

                        {numericId && planActionData && planActionData.length > 1 &&
                            <div>
                                <p className="text-lg font-bold mt-12">Fixer un nouvel objectif</p>
                                <WDPESelector projectId={numericId}
                                              onSelect={handleSelect}
                                              excludeObjectives={[currentProject.classeEnergetiqueObjectif]}/>

                                <div className="flex flex-col mt-12 justify-start ">
                                    <a href="https://wattless.fr/estimation-des-travaux-de-renovation/" target="_blank"
                                       rel="noreferrer"
                                       className="block text-green-600 font-medium underline mt-3">
                                        Comment estimons-nous les travaux à réaliser ?
                                    </a>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {showToast && (
                <WToast
                    message="Nouvelle Objectif enregistré avec succès"
                    type="success"
                    onClose={() => setShowToast(false)} // Masque le toast après fermeture
                />
            )}
        </div>);
};

export default PlanAction;