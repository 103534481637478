import axios from './axiosInstance';
import {User} from "./interfaces/ProjectInterfaces";
import {SubscriptionData} from "./interfaces/ApiInterfaces";

export const getUser = async (): Promise<string[]> => {
    const response = await axios.get(`/users`);
    return response.data.data;
};

export const getUserById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
};

export const getUsers = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
    const response = await axios.post(`/users/${user.id}/informations`, user);
    return response.data.data;
};

export const getUserInfoById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}/informations`);
    return response.data.data;
};

export const infoFacturation = async (userId: number) : Promise<SubscriptionData> => {
    const response = await axios.get(`/users/${userId}/infofacturation`);
    return response.data.data;
};


