import React from 'react';
import './styles.css';
import WTooltip from "../WTooltip";
import {IWPriceProps} from "./types";
import WButton from "../Wbutton";
import {useNavigate} from "react-router-dom";

const WPrice: React.FC<IWPriceProps> = ({elm, year = true}) => {
    const navigate = useNavigate();
    const handle = () => {
        navigate('/payment-form', {
            state: {
                priceId : year ? elm.priceIDAnnuelStripe : elm.priceIDMensuelStripe,
                subscriptionID: elm.id,
                price: year ? elm.prixAnnuelTtc: elm.prixMensuelTtc,
                libelle: elm.libelle,
                year: year,
                pack: elm
            }
        });
    };

    return (
        <div className="price-container">
            <h1 className="price-title">{elm.libelle}</h1>
            <p className="price-description">{elm.description}</p>
            <div className="price-container-inner">
                {elm.details.map((detail) => {
                    return <><span className="price-trait"></span>
                        <div>
                            <span className="price-info">{detail.libelle} :</span>
                            <span className="price-info-value">{detail.valeur}</span>
                            <span className="price-info-bulle"
                                  data-tooltip-id="tooltip"
                                  data-tooltip-content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do"
                                  data-tooltip-place="top"
                            >?</span>
                            <WTooltip id="tooltip"/>
                        </div>
                    </>
                })}
                <span className="price-trait"></span>
            </div>

            <div className="price-price">{year ? elm.prixAnnuelHT : elm.prixMensuelHT} €<span
                className="price-tva">htva</span></div>

            <WButton onClick={handle} variant="secondary" className="mt-6">Commencer</WButton>

        </div>
    );
};

export default WPrice;