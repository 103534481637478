import React, {useEffect, useState} from 'react';
import {useQuery} from "react-query";
import {listProjects} from "../../../api/project";
import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {initProjects, selectAllProjects, setSelectedProject} from "../../../redux/slices/projectSlice";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import WObjective from "../../../components/WObjective";
import {RectangleGroupIcon, TableCellsIcon} from "@heroicons/react/16/solid";
import WLoader from "../../../components/WLoader/index.";
import {PiEyeFill} from "react-icons/pi";
import {FaRegTrashCan} from "react-icons/fa6";
import {Project} from "../../../api/interfaces/ProjectInterfaces";
import WToast from "../../../components/WToast";
import WRemoveProject from "../../../components/WRemoveProject";
import {customMarkerIcon} from "../../../constants";

//const getMap = process.env.GET_MAP;

const ProjectsList: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coordinatesList, setCoordinatesList] = useState<{ lat: number, lng: number, indicateur: string, libelle: string, adresse: string }[]>([]);
    const [isCard, setIsCard] = useState(false);
    const [showDialogDelete, setShowDialogDelete] = useState<boolean>(false);
    const [selectProject, setSelectProject] = useState<Project>();
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastType, setToastType] = useState<"success" | "error" | "info">("success");
    const projects = useSelector(selectAllProjects);

    const handleShowToast = (type: "success" | "error" | "info", message: string) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Ajoute l'événement 'resize' à la fenêtre
        window.addEventListener('resize', handleResize);
        // Nettoie l'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const {isLoading} = useQuery('Projects', async () => {
        const projectData = await listProjects();

        // Récupérer les coordonnées pour chaque projet
        const coordinatesPromises = projectData.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.numeroAdeme === item.numeroAdeme)
        ).map(async (project) => {
            if (project.adresse.latBAN && project.adresse.lngBAN) {
                // Utiliser les coordonnées existantes si elles sont disponibles
                return {
                        lat: (project.adresse.banY ? project.adresse.banY : 0) ,
                        lng: (project.adresse.banX ? project.adresse.banX : 0),
                        indicateur: project.classeEnergetiqueActuel,
                        libelle: 'N° ADEME: ' + project.numeroAdeme,
                        adresse: project.adresse.adresse + ' ' + project.adresse.codePostal + ' ' + project.adresse.ville
                        };
            } else {
                return {lat: 0, lng: 0, indicateur: project.classeEnergetiqueActuel, libelle: project.libelle + ' - N°' + project.numeroAdeme, adresse: project.adresse.adresse + ' ' + project.adresse.codePostal + ' ' + project.adresse.ville};
            }
        });

        const validCoordinates = await Promise.all(coordinatesPromises);
        const filteredCoordinates = validCoordinates.filter(coord => coord.lat !== 0 && coord.lng !== 0);
        setCoordinatesList(filteredCoordinates); // Mettre à jour l'état avec les coordonnées valides

        dispatch(initProjects(projectData));

        return projectData; // Retourne les données de projet
    });

    // Fonction pour supprimer un projet
    const handleDeleteProject = () => {
        handleShowToast("success", "Projet supprimé avec succès");
        setShowDialogDelete(false);
    };

    const handleCloseDialogDeleteProject = () => {
        setShowDialogDelete(false);
    }

    const handleOpenDialogDeleteConfirm = async (project: Project) => {
        setShowDialogDelete(true);
        setSelectProject(project)
    };

    const handleNewProject = async () => {
        navigate('/ademe');
    };

    const handleGoProject = async (projectId: number) => {
        dispatch(setSelectedProject(projectId));
        navigate(`/project/${projectId}/info`);
    }

    if (isLoading) {
        return <WLoader message={'Chargement'}/>;
    }

    const handleHideToast = () => setShowToast(false);

    return (
        <>
            {/* Affichage de la carte avec tous les marqueurs */}
            {coordinatesList.length > 0 && (
                <div className="h-[350px] w-full">
                    <MapContainer
                        center={coordinatesList[0]} // Centrer sur la première coordonnée valide
                        zoom={10}
                        style={{height: '100%', width: '100%'}}
                        className="w-full p-4 rounded-t-[10px]"
                        bounds={coordinatesList.map(coord => [coord.lat, coord.lng])} // Ajuste automatiquement la carte pour inclure tous les marqueurs
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        {/* Affichage de tous les marqueurs */}
                        {coordinatesList.map((coord, index) => (
                            <Marker
                                key={index}
                                position={coord}
                                icon={customMarkerIcon(coord.indicateur)}
                            >
                                <Tooltip>
                                    <div>
                                        <p><strong>{coord.libelle}</strong></p>
                                        <p><strong>{coord.adresse}</strong></p>
                                    </div>
                                </Tooltip>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
            )}

            {projects && (isCard || windowSize.width < 1000) ? <div className="w-full">
                    <div className="flex flex-row flex-wrap justify-between text-center my-8 mx-6 space-y-4">
                        <div className="font-medium flex justify-center items-center text-2xl">Liste de vos
                            projets
                        </div>
                        <div className="flex space-x-4 justify-center items-center">
                            {windowSize.width >= 1000 && <TableCellsIcon className="cursor-pointer h-8 text-[#7c90aa]"
                                                                         onClick={() => setIsCard(false)}/>}
                            <WButton variant="secondary"
                                     onClick={handleNewProject}
                                     className="bottom-4 right-3">Nouveau projet</WButton>
                        </div>
                    </div>
                    <ResponsiveMasonry className="space-x-4 mx-6 "
                                       columnsCountBreakPoints={{1: 1, 700: 2, 1150: 3, 1300: 4}}>
                        <Masonry className="space-x-4 ">
                            {projects.map((project, index) => (
                                <div key={index} onClick={() => handleGoProject(project.id)}
                                     className="mb-4 bg-white w-full rounded-[10px] shadow-md p-8 flex flex-row relative cursor-pointer">
                                    <div className="block flex-col text-left relative w-full">
                                        <div className="font-bold">{project.libelle}</div>
                                        <div className="text-xs">{project.adresse.type + ' - ' + project.numeroAdeme}</div>
                                        <div>{project.adresse.adresse}</div>
                                        <div className="text-xs">{project.adresse.codePostal} {project.adresse.ville}</div>
                                        <div className={`font-bold text-xs ${project.gestionnaire ? 'text-green-500' : 'text-red-500'}`}>Géré par : {project.gestionnaire ? (project.gestionnaire.nom + ' ' + project.gestionnaire.prenom) : 'Aucun Gestionnaire'}</div>
                                        <div className="border border-dashed border-gray-500 my-6"></div>
                                        <div className="font-bold mb-2">Objectif DPE</div>
                                        {project.classeEnergetiqueObjectif ?
                                            <WObjective
                                                level={project.classeEnergetiqueActuel}
                                                objective={project.classeEnergetiqueObjectif}/> :
                                            <WObjective
                                                level={project.classeEnergetiqueActuel}
                                                objective={project.classeEnergetiqueActuel}/>
                                        }
                                        <div className="border border-dashed border-gray-500 my-6"></div>
                                        <div className="font-bold">Estimation budget</div>
                                        <div>{project.resteACharge}</div>
                                        <div>{project.aidesFinancieres}</div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry></div> :
                <div className="text-left w-full p-5 ">
                    <div className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto w-full">

                        {/* Tableau des projets */}
                        {projects && (
                            <div className="rounded-[10px] ">
                                <div className="flex flex-row justify-between text-center m-6">
                                    <div className="font-medium flex justify-center items-center text-2xl">Liste de vos
                                        projets
                                    </div>
                                    <div className="flex space-x-4 justify-center items-center">
                                        {windowSize.width >= 1000 &&
                                            <RectangleGroupIcon className="cursor-pointer h-8 text-[#7c90aa]"
                                                                onClick={() => setIsCard(true)}/>}
                                        <WButton variant="secondary" onClick={handleNewProject}
                                                 className="bottom-4 right-3">
                                            Nouveau projet
                                        </WButton>
                                    </div>
                                </div>
                                <table className="table-auto text-left w-full rounded-[10px]">
                                    <thead>
                                    <tr className="bg-[#899BB4] text-white h-10">
                                        <th className="p-3 font-bold">ID</th>
                                        <th className="p-3 font-bold">Nom du projet</th>
                                        <th className="p-3 font-bold">N°ADEME</th>
                                        <th className="p-3 font-bold">Typologie</th>
                                        <th className="p-3 font-bold">Gestionnaire</th>
                                        <th className="p-3 font-bold">Adresse</th>
                                        <th className="p-3 font-bold">Objectif DPE</th>
                                        <th className="p-3 font-bold">Estimation budget</th>
                                        <th className="p-3 font-bold">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {projects.map((project, index) => (
                                        <tr key={project.id} className={index % 2 === 0 ? 'bg-white cursor-pointer' : 'bg-gray-100 cursor-pointer'}
                                            onClick={() => handleGoProject(project.id)}
                                        >
                                            <td className="p-3 font-bold">#{project.id}</td>
                                            <td className="p-3 font-bold">{project.libelle}</td>
                                            <td className="p-3 font-medium text-sm">{project.numeroAdeme}</td>
                                            <td className="p-3 font-medium text-sm">{project.adresse.type}</td>
                                            <td className={`font-bold p-3 text-sm ${project.gestionnaire ? 'text-green-500' : 'text-red-500'}`}>{project.gestionnaire ? (project.gestionnaire.nom + ' ' + project.gestionnaire.prenom) : 'Aucun Gestionnaire'}</td>
                                            <td className="p-3 font-medium text-sm">
                                                {project.adresse.adresse}<br/>
                                                {project.adresse.codePostal} {project.adresse.ville}
                                            </td>
                                            <td className="flex justify-start items-center p-3 text-center">{project.classeEnergetiqueObjectif ?
                                                <WObjective
                                                    level={project.classeEnergetiqueActuel}
                                                    objective={project.classeEnergetiqueObjectif}/> :
                                                <WObjective
                                                    level={project.classeEnergetiqueActuel}
                                                    objective={project.classeEnergetiqueActuel}/>
                                            }</td>
                                            <td className="p-3">{project.resteACharge}</td>
                                            <td className="p-3">
                                                <div className="h-full flex relative flex-1">
                                                <button
                                                    className="text-[#7C90AA] hover:underline mr-3 text-2xl "
                                                    onClick={() => handleGoProject(project.id)}
                                                    title="Consulter"
                                                >
                                                    <PiEyeFill />
                                                </button>
                                                <button
                                                    className="text-[#7C90AA] hover:text-red-500 hover:underline text-2xl "
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        return handleOpenDialogDeleteConfirm(project);
                                                    }}
                                                    title="Supprimer"
                                                >
                                                    <FaRegTrashCan />
                                                </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>}

            {showDialogDelete && selectProject &&
                <WRemoveProject projectId={selectProject?.id} onCancel={handleCloseDialogDeleteProject}
                                onSuccess={handleDeleteProject}/>}
            {showToast && (
                <WToast
                    message={toastMessage}
                    type={toastType}
                    duration={5000}
                    onClose={handleHideToast}
                />
            )}
        </>
    );
};

export default ProjectsList;
