import {LoginResponse, Token} from "./interfaces/AuthInterfaces";
import axios, {AxiosResponse} from "axios";

const API_URL = process.env.REACT_APP_API_AUTH_URL;

export const login = async (username: string, password: string): Promise<Token> => {
    const response: AxiosResponse<LoginResponse> = await axios.post('/authenticate', {
        username,
        password,
    }, {
        baseURL: API_URL,
    });
    return response.data.data;
};

export const forgotPassword = async (email: string): Promise<null> => {
    const response = await axios.post(`/auth/forgot-password`, {
        email: email
    }, {
        baseURL: API_URL,
    });
    return response.data.data;
};

export const resetPassword = async (token: string, newPassword: string): Promise<null> => {
    const response = await axios.post(`/auth/reset-password`, {
        token: token,
        newPassword: newPassword
    }, {
        baseURL: API_URL,
    });
    return response.data.data;
};