import React from "react";
import {DeperditionEnergetique} from "../../../api/interfaces/ApiInterfaces";
import maison from "../../../assets/images/Maison.png"

interface DeperditionProps {
    deperditionEnergetique: DeperditionEnergetique;
    vsDeperditionEnergetique?: DeperditionEnergetique;
}

const Deperdition: React.FC<DeperditionProps> = ({deperditionEnergetique, vsDeperditionEnergetique}) => {

    const getColorClass = () => (vsDeperditionEnergetique ? "text-gray-400" : "text-red-600");

    return (
        <div
            className="m-2 bg-white flex flex-col flex-1 w-1/2 rounded-[10px] text-center justify-center min-w-[500px] min-h-[400px]">
            <div className="p-8 flex flex-col flex-1">
                <div className="text-left font-bold text-xl mb-3">Déperdition énergétique</div>
                <div className="relative h-full flex flex-col justify-between">
                    <div className="relative flex flex-row justify-between ">
                        {/* Renouvellement de l’air */}
                        <div className="top-0 left-0">
                            <div className="text-gray-400 font-bold text-[14px]">Renouvellement de l’air :</div>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.renouvellementAir.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.renouvellementAir.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <> vs <span className="text-green-600">
                                    {parseInt(vsDeperditionEnergetique.renouvellementAir.donnee || "0")} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.renouvellementAir.donneeEnPourcentage || "0")}%
                                </span></>
                                )}
                            </div>
                        </div>

                        {/* Planchers hauts */}
                        <div className="top-0 right-0">
                            <span className="text-gray-400 font-bold text-[14px]">Planchers hauts :</span>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.plancherHaut.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.plancherHaut.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <div className="flex flex-col"> vs <span className="text-green-600">
                                    {parseInt(vsDeperditionEnergetique.plancherHaut.donnee || "0", 2)} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.plancherHaut.donneeEnPourcentage || "0", 2)}%
                                </span></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="relative flex flex-row justify-between flex-1">
                        {/* Murs */}
                        <div className="right-0 justify-center text-right flex flex-col">
                            <div className="text-gray-400 font-bold text-[14px]">Murs :</div>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.murs.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.murs.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <div className="flex flex-col"> vs <span className="text-green-600">
                                    {parseInt(vsDeperditionEnergetique.murs.donnee || "0", 2)} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.murs.donneeEnPourcentage || "0", 2)}%
                                </span></div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center relative flex-shrink-0">
                            <img src={maison} className="mx-6" alt={'Déperditions énergétique'}/>
                        </div>
                        {/* Menuiseries */}
                        <div className="right-0 justify-center text-left flex flex-col">
                            <div className="text-gray-400 font-bold text-[14px]">Menuiseries :</div>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.menuiserie.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.menuiserie.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <div className="flex flex-col"> vs <span className="text-green-600">
                                    {parseInt(vsDeperditionEnergetique.menuiserie.donnee || "0", 2)} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.menuiserie.donneeEnPourcentage || "0", 2)}%
                                </span></div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="relative flex flex-row justify-between ">
                        {/* Ponts thermiques */}
                        <div className="bottom-0 left-0 ">
                            <div className="text-gray-400 font-bold text-[14px]">Ponts thermiques :</div>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.pontThermiques.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.pontThermiques.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <div className="flex flex-col"> vs <span className="text-[20px]">
                                    {parseInt(vsDeperditionEnergetique.pontThermiques.donnee || "0", 2)} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.pontThermiques.donneeEnPourcentage || "0", 2)}%
                                </span></div>
                                )}
                            </div>
                        </div>

                        {/* Planchers bas */}
                        <div className="bottom-0 right-0">
                            <div className="text-gray-400 font-bold text-[14px]">Planchers bas :</div>
                            <div className={`${getColorClass()} font-bold text-[14px] flex flex-col`}>
                                {parseInt(deperditionEnergetique.plancherBas.donnee || "0", 2)} W/K ={" "}
                                {parseInt(deperditionEnergetique.plancherBas.donneeEnPourcentage || "0", 2)}%
                                {vsDeperditionEnergetique && (
                                    <div className="flex flex-col"> vs <span className="text-green-600">
                                    {parseInt(vsDeperditionEnergetique.plancherBas.donnee || "0", 2)} W/K ={" "}
                                        {parseInt(vsDeperditionEnergetique.plancherBas.donneeEnPourcentage || "0", 2)}%
                                </span></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Deperdition;
