import React, {useState} from 'react';

import iconObj from "../../../../assets/icons/actions-plan2.svg";

import surface from "../../../../assets/images/surface.png";
import calcul from "../../../../assets/images/calcul.png";
import box from "../../../../assets/icons/tool-box.svg";
import evo from "../../../../assets/icons/evo.png";

import {ArrowLeftIcon} from "@heroicons/react/16/solid";

import dpeA from "../../../../assets/icons/DPE_GRAY/DPE A.svg";
import dpeB from "../../../../assets/icons/DPE_GRAY/DPE B.svg";
import dpeC from "../../../../assets/icons/DPE_GRAY/DPE C.svg";
import dpeD from "../../../../assets/icons/DPE_GRAY/DPE D.svg";
import dpeE from "../../../../assets/icons/DPE_GRAY/DPE E.svg";
import dpeF from "../../../../assets/icons/DPE_GRAY/DPE F.svg";
import dpeG from "../../../../assets/icons/DPE_GRAY/DPE G.svg";

import {useSelector} from "react-redux";
import {selectProjectById} from "../../../../redux/slices/projectSlice";
import {useNavigate, useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {generalInformation, getPlanAction, subscriptionDetail} from "../../../../api/project";
import WLoader from "../../../../components/WLoader/index.";
import Menu from "../../menu";
import ApportFree from "../ApportFree";
import EcoTypeEnergies from "../EcoTypeEnergies";
import RepartitionUsage from "../RepartitionUsage";
import Deperdition from "../Deperdition";
import WButton from "../../../../components/Wbutton";
import {CategorieTravauxPlanAction, PlansTravaux} from "../../../../api/interfaces/ApiInterfaces";

import iconWind from "../../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../../assets/icons/icon optim/vlc.svg";
import iconProject from "../../../../assets/icons/icon optim/iso-ext.svg";
import iconPBas from "../../../../assets/icons/icon optim/iso-planche-bas.svg";
import iconPHaut from "../../../../assets/icons/icon optim/iso-planche-haut.svg";
import iconClim from "../../../../assets/icons/icon optim/clim.png";

const iconType: { [key: string]: string } = {
    "MURS": iconProject,
    "MENUISERIES": iconWind,
    "PLANCHERS_BAS": iconPBas,
    "PLANCHERS_HAUTS": iconPHaut,
    "VENTILATION": iconVent,
    "CLIMATISATION": iconClim,
};

const colorDPE: { [key: string]: string } = {
    A: "#14833A",
    B: "#27C63F",
    C: "#ADE333",
    D: "#EBE609",
    E: "#FDA71B",
    F: "#FB6614",
    G: "#DC0404"
}

const dpeImages: { [key: string]: string } = {
    A: dpeA,
    B: dpeB,
    C: dpeC,
    D: dpeD,
    E: dpeE,
    F: dpeF,
    G: dpeG,
};

interface ElementsProps {
    cat: CategorieTravauxPlanAction[];
    plan: PlansTravaux;
}

const Accordion: React.FC<ElementsProps> = ({cat, plan}) => {
    const [activeIndex, setActiveIndex] = useState<string | null>(null);

    // Fonction pour gérer l'ouverture et la fermeture des éléments
    const handleAccordionClick = (cat: string) => {
        setActiveIndex(activeIndex === cat ? null : cat);
    };

    return (
        <div className="rounded-lg flex-1">
            <div className="bg-[#899BB4] rounded-t-2xl h-14 text-white flex flex-row justify-end items-center">
                <div className="font-[600] mr-6">Estimation d’économies / an</div>
                <div className="font-[600] w-[220px] mr-4">Estimation budget</div>
            </div>
            {cat.map((element, index) => (
                <div key={index} className={`border-b border-gray-300 
                ${index === 0 ? 'rounded-lg' : ''}  
                ${index === cat.length - 1 ? 'rounded-b-2xl' : ''}
                   `}>
                    <button
                        className={`flex w-full text-left p-3 h-16 justify-between items-center font-bold
                        ${index === cat.length - 1 ? 'rounded-b-2xl' : ''}
                        ${(activeIndex === element.typeCategorieTravaux || (activeIndex == null && index == 0)) ? 'bg-[#5FBB46] text-white' : 'bg-[#FAFBFD] text-[#7C90AA]'}`}
                        onClick={() => handleAccordionClick(element.typeCategorieTravaux)}>
                        <div><img src={iconType[element.typeCategorieTravaux]}
                                  className="max-h-10 max-w-10 mx-6 inline"
                                  alt={element.typeCategorieTravaux}/>
                            {element.typeCategorieTravaux}</div>
                        <div className="flex flex-row flex-1 justify-end">
                            <div className="mr-6">{element.economieEnergie}</div>
                            <div className="w-[205px]">{element.budget}</div>
                            {(activeIndex === element.typeCategorieTravaux || (activeIndex == null && index == 0)) ?
                                <div
                                    className="ml-1 relative top-2 right-3 w-0 h-0 border-l-8 border-r-8 border-l-transparent border-r-transparent border-t-8 border-t-white">
                                </div> :
                                <div
                                    className="ml-3 relative top-2 right-3 w-0 h-0 border-t-8 border-b-8 border-t-transparent border-b-transparent border-l-8 border-l-gray-500">
                                </div>}
                        </div>
                    </button>

                    <div className="justify-center bg-[#E7EEF8] flex">
                        {(activeIndex === element.typeCategorieTravaux || (activeIndex == null && index == 0)) &&
                            <div className="w-full flex justify-center flex-col min-h-5">
                                {plan[element.typeCategorieTravaux].map((travaux, i) => {
                                    return <div key={i} className="my-3 mx-5">
                                        <div className="m-auto flex-1 p-6 bg-white rounded-[10px] shadow-md relative">
                                            <div
                                                className="font-bold text-2xl text-gray-600">{travaux?.referenceTypeTravaux?.donnee} -
                                                <span
                                                    className="font-normal"> {travaux?.referenceTypeTravaux?.donneeEnPourcentage}</span>
                                            </div>

                                            <div className="flex flex-row">
                                                <div className="w-1/2 flex-1">

                                                    {travaux?.materielRetirer?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="p-3 mt-2 border-2 border-gray-300 w-full">
                                                                <div className="font-bold">
                                                                    {travaux?.materielRetirer?.donnee} : {travaux?.materielRetirer?.donneeEnPourcentage}
                                                                </div>
                                                                <div>
                                                                    {travaux?.performanceTechniqueMaterielRetirer?.donnee} : {travaux?.performanceTechniqueMaterielRetirer?.donneeEnPourcentage}
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {travaux?.materielAjouter?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="p-3 mt-2 border-2 border-gray-300 w-full">
                                                                <div className="font-bold">
                                                                    {travaux?.materielAjouter?.donnee} : {travaux?.materielAjouter?.donneeEnPourcentage}
                                                                </div>
                                                                <div>
                                                                    {travaux?.performanceTechniqueMaterielAjouter?.donnee} : {travaux?.performanceTechniqueMaterielAjouter?.donneeEnPourcentage}
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {travaux?.estimationEconomie?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="text-gray-500">Estimation d’économie :</div>
                                                            <div
                                                                className="p-3 mt-2 font-bold text-green-500 border-2 border-gray-300 w-full">
                                                                {travaux?.estimationEconomie?.donnee}
                                                            </div>
                                                        </div>}

                                                </div>
                                                <div className="w-1/2 flex-1">

                                                    {travaux?.elementPhysiqueConcerne1?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="text-gray-500">Eléments concernés :</div>
                                                            <div className="p-3 mt-2 border-2 border-gray-300 w-full">
                                                                <div>
                                                                    <span>.</span> {travaux?.elementPhysiqueConcerne1?.donnee}
                                                                </div>
                                                                <div>
                                                                    <span>.</span> {travaux?.elementPhysiqueConcerne2?.donnee}
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {travaux?.performanceTechniqueAvantTravaux?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="text-gray-500">Resistance Thermique :</div>
                                                            <div className="p-3 mt-2 border-2 border-gray-300 w-full">
                                                                <div>
                                                                    <span>.</span> {travaux?.performanceTechniqueAvantTravaux?.donnee} : {travaux?.performanceTechniqueAvantTravaux?.donneeEnPourcentage}
                                                                </div>
                                                                <div>
                                                                    <span>.</span> {travaux?.performanceTechniqueApresTravaux?.donnee} : {travaux?.performanceTechniqueApresTravaux?.donneeEnPourcentage}
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {travaux?.estimationBudgetSansAides?.donnee &&
                                                        <div
                                                            className="p-3 h-1/3 flex flex-col justify-end items-start">
                                                            <div className="text-gray-500">Estimation du budget avant
                                                                déduction des aides :
                                                            </div>
                                                            <div
                                                                className="p-3 mt-2 font-bold text-green-500 border-2 border-gray-300 w-full">
                                                                {travaux?.estimationBudgetSansAides?.donnee}
                                                            </div>
                                                        </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

const DetailAction: React.FC = () => {

    /* Récuperation ID param */
    const {id, paId} = useParams<{ id: string, paId: string }>();
    const numericId = id ? parseInt(id) : null;
    const planActionId = paId ? parseInt(paId) : null;
    const currentProject = useSelector(selectProjectById(numericId));

    const navigate = useNavigate();

    const {data: generalInformationData} = useQuery(
        ["projectInfo", numericId],
        async () => {
            return (numericId) ? await generalInformation(numericId) : null;
        }
    );

    const {data: planActionData} = useQuery(['planAction'], async () => {
        if (!numericId) return null;
        return await getPlanAction(numericId);
    }, {enabled: !!numericId});

    const {data: subscriptionDetailData} = useQuery(
        ["detailInfo", planActionId],
        async () => {
            return (numericId && planActionId) ? await subscriptionDetail(numericId, planActionId) : null;
        }
    );

    const handleReturnAction = () => {
        navigate(`/project/${numericId}/action`)
    };

    if (!currentProject || !generalInformationData) {
        return <WLoader message={"Chargement"}/>;
    }

    return (
        <div className="w-full max-w-[1200px]">
            <div className="w-full flex justify-between mt-6">
                <Menu projectId={currentProject.id}/>
            </div>
            <div className="text-left mb-6 mx-3">
                <div className="flex mt-12">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3 flex justify-between w-full">
                        <div className="font-bold text-lg ml-3 mr-3 cursor-pointer" onClick={handleReturnAction}>
                            Plans d&apos;actions /
                            <span className="font-bold text-green-600 uppercase ml-2">
                                {planActionData && planActionData?.find(planAction => planAction.classeEnergetiqueObjectique === currentProject.classeEnergetiqueObjectif)?.refId}
                            </span>
                        </div>
                        <div>
                            <div className="flex text-[#7C90AA] font-bold underline cursor-pointer"
                                 onClick={handleReturnAction}>
                                <ArrowLeftIcon className="h-4 w-4 relative top-1 mr-1"/> Retour au plan d’action
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md relative mt-6">
                    <div className="flex flex-col p-3 md:p-6 ">
                        <div className="flex w-full flex-row justify-center text-sm text-center">
                            <div
                                className="m-2 flex flex-row flex-1 min-w-[250px] items-center shadow-md p-3 text-white bg-[#00257b99] rounded-[10px] text-center justify-center"
                                style={{backgroundColor: colorDPE[subscriptionDetailData?.planAction?.classeEnergetiqueObjectique as string]}}
                            >
                                <div className="w-1/2"><img
                                    src={dpeImages[subscriptionDetailData?.planAction?.classeEnergetiqueObjectique as string]}
                                    alt={subscriptionDetailData?.planAction?.classeEnergetiqueObjectique}
                                    className="h-40 m-3"/>
                                </div>
                                <div className="flex flex-col text-center items-center w-1/2">
                                    <span className="font-bold">Objectif DPE</span>
                                    <span
                                        className="text-[4rem] leading-normal">{subscriptionDetailData?.planAction?.classeEnergetiqueObjectique}</span>
                                </div>
                            </div>
                            <div
                                className="m-2 flex flex-col justify-between flex-1 min-w-[250px] shadow-md p-3 text-white bg-green-600 rounded-[10px]"
                            >
                                <div className="text-white flex-1 mt-3 text-base">
                                    Estimation des économies annuelles d’énergie réalisées après travaux
                                </div>
                                <div className="text-white font-bold text-xl flex-1">
                                    {subscriptionDetailData?.planAction?.surfacePerdue}
                                    395 kWhep /m² - 18 kgCo2/m²
                                    350 € - 480€
                                    {subscriptionDetailData?.planAction.previsionEconomie}
                                </div>
                            </div>
                            <div
                                className="m-2 flex flex-row flex-1 min-w-[250px]
                                    items-center shadow-md p-3 text-white bg-gray-100 border-gray-400 border rounded-[10px] text-center justify-center"
                            >
                                <div className="flex w-1/2 justify-center items-center"><img
                                    src={surface}
                                    className="h-[80%] w-[80%]"
                                    alt="Surface"/>
                                </div>
                                <div className="flex flex-col text-center items-center w-1/2">
                                    <div className="text-black flex-1 mt-3 text-base">
                                        Estimation de la surface perdue après travaux
                                    </div>
                                    <div className="text-black font-bold text-xl flex-1">
                                        {subscriptionDetailData?.planAction?.surfacePerdue} m²
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mx-2">
                            <div
                                className="flex bg-[#F7FCFF] items-center p-2 space-x-10 justify-between  text-sm border border-[#00A1FF] rounded-lg text-center">
                                <div className='text-left text-[16px] flex flex-row p-3'>
                                    <img src={calcul} alt="calcul" className="h-full mr-3"/>
                                    <div>
                                        ESTIMATION DU<br/> BUDGET TRAVAUX
                                        <div className="text-[#7C90AA] text-[13px]">Montants hors TVA</div>
                                    </div>
                                </div>
                                <div className='text-left text-[#7C90AA] text-[15px]'>
                                    Budget global
                                    <div className="text-[#5FBB46] font-medium text-lg">
                                        {subscriptionDetailData?.planAction.budget}
                                    </div>
                                </div>
                                <div className="font-bold text-3xl">-</div>
                                <div className='text-left text-[#7C90AA] text-[15px]'>
                                    Montant des aides
                                    <div className="text-[#5FBB46] font-medium text-lg">
                                        {subscriptionDetailData?.planAction.montantAides}
                                    </div>
                                </div>
                                <div className="font-bold text-3xl">=</div>
                                <div className='text-left text-[#7C90AA] text-[15px]'>
                                    Reste à charge
                                    <div className="text-[#5FBB46] font-medium text-lg">
                                        {subscriptionDetailData?.planAction.resteACharge}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="p-3">
                        <div className="bg-white relative">
                            <div className="flex flex-col p-3 md:p-6 ">
                                <p className="text-lg font-bold mb-6">
                                    <img src={box} alt="box" className="inline h-10 mr-3"/>
                                    Liste des travaux conseillés pour atteindre votre objectif DPE</p>
                                {subscriptionDetailData?.categorieTravauxPlanActionInfo && subscriptionDetailData?.plansTravaux &&
                                    <Accordion cat={subscriptionDetailData?.categorieTravauxPlanActionInfo}
                                               plan={subscriptionDetailData?.plansTravaux}/>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex mt-12 mx-2 font-bold text-xl mb-3 justify-between">
                    <div className="flex">
                        <img src={evo} alt="evo" className="inline h-9 mr-3 relative bottom-1"/>
                        <span className="h-[35px] flex items-center">Evolutions et économies d’énergies réalisées</span>
                    </div>
                    <div className="flex h-[35px] items-center">
                        <div className="mr-6">
                            <label className="flex items-center text-gray-400 text-base font-normal">
                                <div className="h-5 w-5 mr-2 bg-gray-300"></div>
                                Avant les travaux
                            </label>
                        </div>
                        <div>
                            <label className="flex items-center text-gray-400 text-base font-normal">
                                <div className="h-5 w-5 mr-2 bg-green-500"></div>
                                Après les travaux sélectionnés
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="flex flex-row">
                        <ApportFree apportInterne={parseInt(subscriptionDetailData?.nouveauApportInterne ?? "0") ?? 0}
                                    apportSolaire={parseInt(subscriptionDetailData?.nouveauApportSolaire ?? "0") ?? 0}
                                    vsApportInterne={parseInt(subscriptionDetailData?.ancienApportInterne ?? "0") ?? 0}
                                    vsApportSolaire={parseInt(subscriptionDetailData?.ancienApportSolaire ?? "0") ?? 0}
                        />
                        {subscriptionDetailData?.ancienRepartitionParEnergie && <EcoTypeEnergies
                            repartitionParEnergie={subscriptionDetailData?.ancienRepartitionParEnergie}
                            vsRepartitionParEnergie={subscriptionDetailData?.ancienRepartitionParEnergie}/>}
                    </div>
                    <div className="flex flex-row">
                        {subscriptionDetailData?.ancienDeperditionEnergetique &&
                            <Deperdition
                                deperditionEnergetique={subscriptionDetailData?.ancienDeperditionEnergetique}
                                vsDeperditionEnergetique={subscriptionDetailData?.ancienDeperditionEnergetique}
                            />}

                        {subscriptionDetailData?.ancienRepartitionUsageParEnergie &&
                            <RepartitionUsage
                                repartitionUsageParEnergie={subscriptionDetailData?.ancienRepartitionUsageParEnergie}
                                vsRepartitionUsageParEnergie={subscriptionDetailData?.ancienRepartitionUsageParEnergie}
                            />}
                    </div>
                </div>

                <div className="flex mt-12 mx-2">
                    <div
                        className="text-[19px] leading-7 text-[#5FBB46] border-4 border-black font-bold h-9 w-9 text-center rounded-full">€
                    </div>
                    <div className="w-[calc(100%-2.25rem)] h-[35px]">
                        <div className="flex items-center h-full font-bold text-lg ml-3 mr-3">Aides financières
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-2 relative mt-6">
                    <div className="flex flex-col p-3 md:p-10">
                        <div className="flex justify-between mb-10">
                            <div>Selon les <span className="text-[#60AD23] underline">informations que vous avez renseignées</span><br/>
                                vous avez droit aux aides suivantes
                            </div>
                            <WButton variant="secondary" href="https://wattless.fr/prestataire-rge/">Trouver un
                                accompagnateur MaPrimRenov’</WButton>
                        </div>

                        {subscriptionDetailData?.aidesFinancieresList.map((aide, aideIndex) => {
                            return <div key={aideIndex}
                                        className="flex p-4 mb-6 bg-[#F7FCFF] items-center space-x-10 justify-between text-sm border border-[#00A1FF] rounded-lg text-center">
                                <div className="text-left">
                                    <div className="text-[#00A1FF] underline text-2xl font-bold">{aide.titre}</div>
                                    <div className="text-lg">{aide.description}</div>
                                </div>
                                <div className="text-right text-lg">
                                    <div>Montant estimé de l’aide :</div>
                                    <div className="text-[#5FBB46] font-bold">{aide.montant}</div>
                                </div>
                            </div>
                        })}

                    </div>
                </div>


            </div>

        </div>
    );
};

export default DetailAction;