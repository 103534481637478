import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import {useDispatch, useSelector} from 'react-redux';
import {useMutation, useQuery} from "react-query";
import {fetchProjectValidation, removeProject as apiRemoveProject} from "../../../api/project";
import {removeCurrentProject, selectCurrentProject, setValidation} from "../../../redux/slices/projectSlice";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import './styles.css';
import iconCheck from '../../../assets/icons/check.svg';
import {useNavigate} from "react-router-dom";
import {customMarkerIcon} from "../../../constants";

const Validation: React.FC = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentProject = useSelector(selectCurrentProject);
    const [coordinates, setCoordinates] = useState<{ lat: number, lng: number, indicateur: string } | null>(null);

    const {data: validationData, isLoading} = useQuery(['validationData', currentProject?.id], async () => {
        if (!currentProject) return null;

        // Récupérer les données de validation
        const validationData = await fetchProjectValidation(currentProject.id);

        // Géocoding : Convertir l'adresse en latitude et longitude
        const provider = new OpenStreetMapProvider();
        const results = await provider.search({query: validationData.adresse});

        if (results.length > 0) {
            const {x: lng, y: lat, } = results[0];

            setCoordinates({lat, lng, indicateur: currentProject.classeEnergetiqueActuel});
            return {...validationData, coordinates: {lat, lng}}; // Ajoute les coordonnées aux données
        }

        return {...validationData, coordinates: null}; // Si pas de coordonnées trouvées
    }, {
        enabled: !!currentProject,
    });

    const handleNext = () => {
        if (validationData && currentProject) {
            dispatch(setValidation({projectId: currentProject.id, validation: validationData}));
            navigate('/financial/choice');
        }
    };

    const handlePrevious = () => {
        navigate('/ademe')
    };

    const {mutate} = useMutation(
        async ({projectId}: { projectId: number }) => {
            await apiRemoveProject(projectId);
            dispatch(removeCurrentProject());

            return true;
        },
        {
            onSuccess: () => {
                navigate('/ademe');
            }
        });

    const handleAbandonner = () => {
        if (currentProject) {
            mutate({projectId: currentProject?.id});
        } else {
            navigate('/projects');
        }
    };



    if (isLoading) {
        return <div>Chargement des données de validation...</div>;
    }

    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    return (
        <>
            <div className="text-left mb-6 w-full max-w-[1000px]">
                <div className="flex mt-6 mx-6">
                    <img src={iconCheck} alt="Check Icon" className="border-2 border-[#899BB4] p-1.5 rounded h-7"/>
                    <div>
                        <div className="font-bold text-lg  ml-3 mr-3">Validation</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Nous avons récupéré les données suivantes sur l’ADEME.<br/>
                            Validez qu’il s’agit bien de l’actif que vous désirez gérer
                        </div>
                    </div>
                </div>

                <div
                    className="flex flex-col flex-1 bg-white rounded-[10px] shadow-md m-auto mt-2 relative mx-6">
                    {/* Affichage de la carte avec un marqueur si les coordonnées sont disponibles */}
                    {coordinates && (
                        <div className="h-[300px] w-full mb-6">
                            <MapContainer
                                center={coordinates}
                                zoom={25}
                                style={{height: '100%', width: '100%'}}
                                className="w-full p-4 rounded-t-[10px]"
                            >
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                <Marker position={coordinates} icon={customMarkerIcon(coordinates.indicateur)}>
                                    <Popup>{validationData?.adresse}</Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    )}
                    {/* Tableau des données du projet courant */}
                    {currentProject ? (
                        <div className="m-2 md:mx-12">
                            <table className="table-auto text-left w-full">
                                <tbody>
                                <tr className="bg-gray-100">
                                    <td className="p-3 font-bold">Adresse :</td>
                                    <td className="p-3">{validationData?.adresse}</td>
                                </tr>
                                <tr>
                                    <td className="p-3 font-bold">Ville :</td>
                                    <td className="p-3">{validationData?.ville}</td>
                                </tr>
                                <tr className="bg-gray-100">
                                    <td className="p-3 font-bold">Code postal :</td>
                                    <td className="p-3">{validationData?.codePostal}</td>
                                </tr>
                                <tr>
                                    <td className="p-3 font-bold">Typologie :</td>
                                    <td className="p-3">{validationData?.type}</td>
                                </tr>
                                <tr className="bg-gray-100">
                                    <td className="p-3 font-bold">Surface habitable :</td>
                                    <td className="p-3">{validationData?.surfaceHabitable} m²</td>
                                </tr>
                                <tr>
                                    <td className="p-3 font-bold">Altitude :</td>
                                    <td className="p-3">{validationData?.altitude}</td>
                                </tr>
                                <tr className="bg-gray-100">
                                    <td className="p-3 font-bold">Zone climatique :</td>
                                    <td className="p-3">{validationData?.zoneClimatique}</td>
                                </tr>
                                <tr>
                                    <td className="p-3 font-bold">Énergies :</td>
                                    <td className="p-3">{validationData?.energies}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="text-center">Aucune donnée disponible pour le projet sélectionné.</div>
                    )}

                    <div className="flex m-2 md:mx-12">
                    <WButton
                        onClick={handleAbandonner}
                        className="mb-6 !border-[1px] border-gray-300 !font-normal w-full rounded-lg !text-gray-500 max-w-[650px]">
                        Ce n’est pas le bon actif. Renseigner un autre N° ADEME
                    </WButton>
                    </div>

                    <div className="flex justify-between pb-10 pt-3 m-3 md:mx-12">
                        <WButton onClick={handlePrevious} variant="primary"
                                 className="bottom-4 right-3 !font-normal !border-[1px]">Précédent</WButton>
                        <WButton onClick={handleNext} variant="secondary"
                                 className=" bottom-4 right-3">Valider et poursuivre</WButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Validation;
