import React from "react";
import {useMutation} from "react-query";
import WModalConfirmation from "../WModalConfirmation";
import {removeProject as apiRemoveProject} from "../../api/project";
import {useDispatch} from "react-redux";
import {removeProject as removeProjectRedux} from "../../redux/slices/projectSlice";
import { TrashIcon, ArrowRightEndOnRectangleIcon } from '@heroicons/react/16/solid';

interface WRemoveProjectProps {
    projectId: number;
    onCancel?: () => void;
    onSuccess?: () => void;
}

const WRemoveProject: React.FC<WRemoveProjectProps> = ({projectId, onCancel, onSuccess}) => {

    const dispatch = useDispatch();
    const { mutate: removeProject } = useMutation(
        async (projectId: number) => {
            if (!projectId) throw new Error("Invalid project ID");
            dispatch(removeProjectRedux(projectId));

            return await apiRemoveProject(projectId);
        },
        {
            onSuccess: () => {
                onSuccess?.();
            },
            onError: () => {
                onCancel?.();
            },
        }
    );

    const handleConfirm = () => {removeProject(projectId)};
    const handleCancel= () => {
        onCancel?.();
    }

    return (<WModalConfirmation
                isOpen={true}
                title={`Êtes-vous certain de vouloir effacer votre projet ?`}
                message={`Cette action est irréversible et entrainera l'effacement définitif de votre projet ainsi que des données qui lui sont liées.`}
                footer={`Confirmez ou abandonnez votre choix`}
                titleConfirme={<><ArrowRightEndOnRectangleIcon className="inline h-5 mr-3" />Abandonner</>}
                titleCancel={<><TrashIcon className="inline h-5 mr-3" />Effacer définitivement</>}
                confirmButtonClassName="!bg-gray-400"
                onConfirm={handleCancel}
                onCancel={handleConfirm}
                onClose={() => {
                    console.log("CLOSE");
                }}
            />
    );
};

export default WRemoveProject;
