import React, {useState} from "react";
import {RepartitionUsageParEnergie} from "../../../api/interfaces/ApiInterfaces";
import WTooltip from "../../../components/WTooltip";
import {colorEnergies} from "../../../constants";

interface RepartitionUsageProps {
    repartitionUsageParEnergie: RepartitionUsageParEnergie[];
    vsRepartitionUsageParEnergie?: RepartitionUsageParEnergie[];
}

const getUsageRepartition = (ef: boolean, repartitions: Array<RepartitionUsageParEnergie>) => {
    // Initialisation d'un objet pour regrouper les résultats
    const repartitionUsageParEnergieMerges: Record<string, {
        libelle: string;
        repartitionParUsageEnergies: number;
        consos: number;
        data: RepartitionUsageParEnergie[];
    }> = {};

    // Parcourir une seule fois pour regrouper les données et calculer les totaux
    repartitions.forEach((rep) => {
        if (rep.ef === ef && rep.typeUsage !== null) {
            // Initialiser la clé si elle n'existe pas
            if (!repartitionUsageParEnergieMerges[rep.typeUsage]) {
                repartitionUsageParEnergieMerges[rep.typeUsage] = {
                    libelle: rep.typeUsage,
                    repartitionParUsageEnergies: 0,
                    consos: 0,
                    data: [],
                };
            }

            // Mise à jour des données et des totaux
            const usageGroup = repartitionUsageParEnergieMerges[rep.typeUsage];
            usageGroup.data.push(rep);
            usageGroup.consos += rep.conso;
            usageGroup.repartitionParUsageEnergies += rep.repartitionParUsage;
        }
    });

    return Object.values(repartitionUsageParEnergieMerges);
};

const getVsUsage = (
    libelle: string,
    vsRepartitionUsage: Array<{
        libelle: string;
        repartitionParUsageEnergies: number;
        consos: number;
        data: RepartitionUsageParEnergie[];
    }>
) => {
    // Find the matching usage in the vs array
    const usage = vsRepartitionUsage.find((item) => item.libelle === libelle);
    // Return the matched usage or a default structure with zero values
    return (
        usage || {
            libelle,
            repartitionParUsageEnergies: 0,
            consos: 0,
            data: [],
        }
    );
};


const RepartitionUsage: React.FC<RepartitionUsageProps> = ({
                                                               repartitionUsageParEnergie,
                                                               vsRepartitionUsageParEnergie
                                                           }) => {

    const [KWhEF, setKWhEF] = useState(true);

    return <div
        className="w-1/2 m-2 bg-white flex flex-col flex-1 rounded-[10px] text-center justify-center min-w-[370px]">
        <div className="p-8">
            <div className="text-left font-bold text-xl">Répartition par usages</div>
            <div className="text-left text-sm mt-2 pb-3">
                        <span onClick={() => setKWhEF(true)}
                              className={`hover:underline cursor-pointer ${KWhEF ? 'text-green-500 font-bold' : 'text-black'}`}>KWhEF</span>
                <span onClick={() => setKWhEF(false)}
                      className={`hover:underline cursor-pointer ml-4 ${!KWhEF ? 'text-green-500 font-bold' : 'text-black'}`}>KWhEP</span>
            </div>
            <div className="flex flex-col flex-1 justify-center  space-y-4">
                {vsRepartitionUsageParEnergie ?
                    getUsageRepartition(KWhEF, repartitionUsageParEnergie).map((elm, i) => {

                        const vsUsage = getVsUsage(
                            elm.libelle,
                            getUsageRepartition(KWhEF, vsRepartitionUsageParEnergie)
                        );

                        return <div key={i}>
                            <div className="font-bold text-left">{elm.libelle} : {elm.consos} KWhEF / An</div>
                            <div className="w-full flex relative">
                                <div
                                    style={{width: `${Math.trunc(elm.repartitionParUsageEnergies || 0)}%`,}}
                                    className="h-5 bg-gray-300 rounded relative top-0">
                                </div>

                                <div
                                    style={{width: `${vsUsage.repartitionParUsageEnergies}%`,}}
                                    className="h-5 bg-green-500 rounded absolute top-0">
                                </div>

                                <div className="text-[#00A1FF] font-bold px-3 h-5">
                                    {vsUsage.repartitionParUsageEnergies - elm.repartitionParUsageEnergies}%
                                </div>
                            </div>
                        </div>
                    })
                    :
                    getUsageRepartition(KWhEF, repartitionUsageParEnergie).map((elm, i) => {
                        return <div key={i}>
                        <div className="text-left">{elm.libelle} : {elm.consos} KWhEF / An</div>
                            <div className="w-full flex">
                                {elm.data.map((data, di) => {
                                    return <>
                                        <div key={di}
                                             data-tooltip-id={`tooltip${di}`}
                                             data-tooltip-content={`${data.typeEnergie} ${Math.trunc(data.repartitionParUsageEnergie)}%`}
                                             data-tooltip-place="top"
                                             style={{
                                                 width: `${Math.trunc(data.repartitionParUsageEnergie || 0)}%`,
                                                 backgroundColor: colorEnergies[data.idTypeEnergie || 1],
                                             }}
                                             className="h-5">
                                        </div>
                                        <WTooltip id={`tooltip${di}`}/></>
                                })}
                                <div
                                    className="text-[#00A1FF] font-bold px-3 h-5">{elm.repartitionParUsageEnergies}%
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
};

export default RepartitionUsage;