import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {
    financialAssistance,
    financialParams, getStatusDemandeur,
    getUsageActif, lancerCalcul
} from "../../../api/project";
import {useSelector} from "react-redux";
import {selectCurrentProject} from "../../../redux/slices/projectSlice";
import {useTranslation} from "react-i18next";
import WInfo from "../../../components/WInfo";
import WLoader from "../../../components/WLoader/index.";

const Financial: React.FC = () => {
        const navigate = useNavigate();
        const {t} = useTranslation();

        const [status, setStatus] = useState('');
        const [usage, setUsage] = useState('');
        const [ecoLoan, setEcoLoan] = useState(true);
        const [revenuFiscal, setRevenuFiscal] = useState(0);
        const [nombrePersonnes, setNombrePersonnes] = useState(0);
        const [showError, setShowError] = useState<boolean>(false);
        const [isLoading, setIsLoading] = useState<boolean>(false);

        const currentProject = useSelector(selectCurrentProject);

        const {mutate} = useMutation(async ({projectId, financialParams}: {
            projectId: number, financialParams: financialParams
        }) => {
            setIsLoading(true);
            await financialAssistance(projectId, financialParams);
            await lancerCalcul(projectId);
        }, {
            onSuccess: () => {
                setIsLoading(false);
                navigate('/objective');
            }
        });

        const {data: usageActif} = useQuery(['usageActif'], async () => {
            return await getUsageActif();
        });

        const {data: statusDemandeur} = useQuery(['statusDemandeur'], async () => {
            return await getStatusDemandeur();
        });

        const handleSubmit = (e: React.FormEvent) => {
            e.preventDefault();
            if (status != '' && usage != '' && revenuFiscal != null && nombrePersonnes != null && ecoLoan != null) {
                setShowError(false);
                if (currentProject?.id) {
                    mutate({
                        projectId: currentProject.id,
                        financialParams: {
                            statusDemandeur: status,
                            usageActif: usage,
                            dernierRevenuFiscalReference: revenuFiscal,
                            nbrPersonnesDeclarees: nombrePersonnes,
                            dejaBeneficierEcoPret: ecoLoan
                        }
                    });
                }
            } else {
                setShowError(true);
            }

        };

        const handlePrevious = () => {
            navigate('/financial/choice')
        };

        // Vérifie si currentProject est nul
        if (!currentProject) {
            return <div>Aucun projet sélectionné.</div>;
        }

        if (isLoading) {
            return <WLoader
                message={'Merci de patienter le temps de déterminer les meilleurs offres de plans de travaux pour votre actif'}/>
        }

        return (
            <>
                <div className="text-left mb-6">
                    <div className="flex mt-12 mx-6">
                        <div
                            className="text-[19px] leading-7 text-[#5FBB46] border-4 border-[#899BB4] font-bold h-9 w-9 text-center rounded-full">€
                        </div>
                        <div className="w-[calc(100%-2.25rem)]">
                            <div className="font-bold text-lg ml-3 mr-3">Aides financières et subventions</div>
                            <div className="font-normal m-auto ml-3 mr-3 max-w-[600px]">
                                Vous pouvez éventuellement bénéficier d’aides financières pour vous aider dans votre
                                projet de rénovation.
                            </div>
                        </div>
                    </div>

                    {/* Validateur */}
                    {showError &&
                        <div className="max-w-[600px] mb-0">
                            <WInfo variant="error">Les champs ci-dessous doivent être renseignés.</WInfo>
                        </div>
                    }

                    <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12 ">
                        <div className="flex flex-col p-6 md:p-12 ">

                            <form onSubmit={handleSubmit} className="space-y-10">

                                {/* Status du demandeur */}
                                <div>
                                    <h2 className="font-medium text-black text-base mb-2">
                                        Status du demandeur par rapport au bien objet de la demande
                                        <span className="text-red-500 ml-1">*</span>
                                        :
                                    </h2>
                                    <div className="flex flex-wrap m-8">
                                        {statusDemandeur && statusDemandeur.map(
                                            (elm, index) =>
                                                <label key={index}
                                                       className="flex items-center text-base font-normal mr-4">
                                                    <input
                                                        type="radio"
                                                        name="status"
                                                        value={elm}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                                    />
                                                    {t(elm)}
                                                </label>
                                        )}
                                    </div>
                                </div>

                                <div className="border-dashed border-t border-gray-400 my-14"></div>

                                {/* Usage du bien */}
                                <div>
                                    <h2 className="font-medium text-black text-base mb-2">
                                        Usage du bien objet par la demande
                                        <span className="text-red-500 ml-1">*</span>
                                        :
                                    </h2>
                                    <div className="flex flex-wrap m-8">
                                        {usageActif && usageActif.map(
                                            (elm, index) =>
                                                <label key={index}
                                                       className="flex items-center  text-base font-normal mr-4">
                                                    <input
                                                        type="radio"
                                                        name="usage"
                                                        value={elm}
                                                        onChange={(e) => setUsage(e.target.value)}
                                                        className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                                    />
                                                    {t(elm)}
                                                </label>
                                        )}
                                    </div>
                                </div>

                                <div className="border-dashed border-t border-gray-400 my-6"></div>

                                {/* Dernier revenu fiscal de référence */}
                                <div>
                                    <h2 className="font-medium text-black text-base mb-2 inline">
                                        Dernier revenu fiscal de référence du ménage
                                        <span className="text-red-500 ml-1">*</span>
                                        :
                                    </h2>
                                    <input
                                        type="number"
                                        id="revenuFiscal"
                                        name="revenuFiscal"
                                        value={revenuFiscal}
                                        onChange={(e) => setRevenuFiscal(Number(e.target.value))}
                                        placeholder="Euros"
                                        className="mx-3 text-right border-[#899BB433] p-2 border rounded-lg shadow-sm bg-[#F5F6FA]"
                                    /><span className="items-center text-base font-normal inline">Euros</span>
                                </div>

                                <div className="border-dashed border-t border-gray-400 my-6"></div>

                                {/* Nombre de personnes déclarées */}
                                <div>
                                    <h2 className="font-medium text-black text-base mb-2 inline">
                                        Nombre de personnes déclarées sur votre dernier avis d’impôt
                                        <span className="text-red-500 ml-1">*</span>
                                        :
                                    </h2>
                                    <input
                                        type="number"
                                        id="nombrePersonnes"
                                        name="nombrePersonnes"
                                        value={nombrePersonnes}
                                        onChange={(e) => setNombrePersonnes(Number(e.target.value))}
                                        placeholder="Nombre de personnes"
                                        className="mx-3 text-right border-[#899BB433] p-2 border rounded-lg shadow-sm w-[200px] bg-[#F5F6FA]"
                                    />
                                </div>

                                <div className="border-dashed border-t border-gray-400"></div>

                                {/* Eco-Prêt à taux zéro */}
                                <div>
                                    <h2 className="font-medium text-black text-base mb-2">
                                        Est-ce que le demandeur a déjà bénéficié d’un Eco-Prêt à taux zéro pour votre
                                        logement ?
                                        <span className="text-red-500 ml-1">*</span>
                                    </h2>
                                    <div className="flex space-x-4 m-8">
                                        <label className="flex items-center  text-base font-normal">
                                            <input
                                                type="radio"
                                                name="ecoLoan"
                                                checked={ecoLoan}
                                                onChange={() => setEcoLoan(true)}
                                                className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                            />
                                            Oui
                                        </label>
                                        <label className="flex items-center text-base font-normal">
                                            <input
                                                type="radio"
                                                name="ecoLoan"
                                                checked={!ecoLoan}
                                                onChange={() => setEcoLoan(false)}
                                                className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                            />
                                            Non
                                        </label>
                                    </div>
                                </div>
                            </form>
                            {/* Submit button */}
                            <div className="flex justify-between mt-28">
                                <WButton variant="primary" onClick={handlePrevious}
                                         className=" bottom-4 right-3">Précédent</WButton>
                                <WButton variant="secondary" onClick={handleSubmit}
                                         className=" bottom-4 right-3">Valider et poursuivre</WButton>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
;

export default Financial;