export enum Status {
    DANGER = "DANGER",
    WARNING = "WARNING",
    SUCCESS = "SUCCESS",
}

export interface ILoiClimatResilience {
    description: string;
    action: string;
    code: string;
    status: Status;
}

// Interface pour les rôles utilisateur
export interface Role {
    id: number;
    name: string;
    description: string | null;
    deleted: boolean;
    dateCreated: string;
    dateDeleted: string | null;
    dateUpdated: string | null;
}

// Interface pour l'utilisateur
export interface User {
    id: number;
    username: string;
    password: string;
    roles: Role[];
    nom: string | null;
    prenom: string | null;
    email: string | null;
    mobile: string | null;
    client: never | null;
    verifyMail: boolean;
    resetPassword: boolean;
    deleted: boolean;
    dateCreated: string;
    dateDeleted: string | null;
    dateUpdated: string | null;
    nbrProjects: number | null;
    telephone: string | null;
    societe: string | null;
    role: string;
    siret: string | null;
    adresse: string | null;
    ville: string | null;
    codePostal: string | null;
    afficherInfosFacturation: boolean;

}

export interface Address {
    adresse: string;
    ville: string;
    codePostal: string;
    latBAN?: number | null;
    banID?: number | null;
    banX?: number | null;
    banY?: number | null;
    type: string;
    surfaceAuSol?: number | null;
    surfaceHabitable: string;
    energies?: string | null;
    lngBAN?: number | null;
    altitude?: string | null;
    zoneClimatique?: string | null;
}

// Interface pour le projet
export interface Project {
    id: number;
    user: User;
    client: never | null;
    code: string;
    libelle: string;
    numeroAdeme: string;
    classeEnergetiqueActuel: string;
    classeEnergetiquePreco: string | null;
    classeEnergetiqueObjectif: string;
    status: string;
    aidesFinancieres: boolean;
    economieEnergie: string;
    budget: string;
    montantAides: string;
    resteACharge: string;
    deleted: boolean;
    dateCreated: string;
    dateDeleted: string | null;
    dateUpdated: string | null;
    adresse: Address;
    userInfo: User;
    dateCreation: Date;
    gestionnaire: User;
}

export interface PlanActionData {
    id: number;
    refId: string;
    typePlan: string;
    classeEnergetiqueObjectique: string;
    information: string;
    previsionEconomie: string;
    travaux: Travail[];
    surfacePerdue: string;
    economieEnergie: string | undefined;
    budget: string | undefined;
    montantAides: string | undefined;
    resteACharge: string | undefined;
}

export interface AideFinanciere {
    titre: string;
    description: string;
    montant: string;
    montantMin: number;
    montantMax: number;
    descriptionMontant: string;
}

interface Travail {
    id: number;
    libelle: string;
}

// Interface pour la réponse
export interface ListProjectResponse {
    status: number;
    message: string | null;
    data: Project[];
}

// Interface pour la réponse
export interface CreateProjectResponse {
    status: number;
    message: string | null;
    data: Project;
}

export interface IFinancial {
    id: number;
    project: Project;
    client: string | null;
    statusDemandeur: string;
    usageActif: string;
    dernierRevenuFiscalReference: number;
    nbrPersonnesDeclarees: number;
    dejaBeneficierEcoPret: boolean;
}

