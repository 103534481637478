import React from 'react';
import WButton from "../../../components/Wbutton";
import iconObj from "../../../assets/icons/actions-plan2.svg";
import iconWind from "../../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../../assets/icons/icon optim/chauffage.svg";
import iconProject from "../../../assets/icons/icon optim/iso-ext.svg";

import dpeA from "../../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../../assets/icons/DPE/DPE G.svg";

import {useDispatch, useSelector} from "react-redux";
import {selectCurrentProject, updateProject as updateProjectRedux} from "../../../redux/slices/projectSlice";
import {useNavigate} from "react-router-dom";
import WObjective from "../../../components/WObjective";
import {useMutation, useQuery} from "react-query";
import {getPlanAction, updateProject} from "../../../api/project";
import {Project} from "../../../api/interfaces/ProjectInterfaces";
import WDPESelector from "../../../components/WDPESelector";

/*
const classeInferior = (classe: string) => {
    const classes = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const index = classes.indexOf(classe);

    return (index === -1 || index === classes.length - 1) ? 'G' : classes[index - 1];
};
 */

const Objective: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentProject = useSelector(selectCurrentProject);
    const classeActuelle = currentProject?.classeEnergetiqueActuel || 'G'; // Valeur par défaut

    const {data: planAction} = useQuery(['planAction'], async () => {
        if (!currentProject) return null;

        return   await getPlanAction(currentProject?.id);
    }, {enabled: !!currentProject});

    /*
    const {mutate: selectCustom} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    }, {
        onSuccess: () => {
            navigate('/objective');
        }
    });
     */

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    const {mutate: select} = useMutation(async ({project}: {
        project: Project
    }) => {
        return await updateProject(project);
    });

    const handlePrevious = () => {
        navigate('/financial/choice')
    };

    const handleSelect = (objective: string) => {
        if (currentProject) {
            const newProject = {
                ...currentProject,
                classeEnergetiqueObjectif: objective
            };

            select({
                project: newProject
            });

            dispatch(updateProjectRedux(newProject));
            navigate('/project/'+ currentProject.id + '/info');
        }
    };

    // Vérifie si currentProject est null
    if (!currentProject) {
        return <div>Aucun projet sélectionné.</div>;
    }

    return (
        <>
            <div className="text-left mb-6">
                <div className="flex mt-12 mx-6">
                    <img src={iconObj} alt="Check Icon" className="h-7 text-gray-500"/>
                    <div className="ml-3">
                        <div className="font-bold text-lg  ml-3 mr-3">Objectif DPE</div>
                        <div className="font-normal m-auto ml-3 mr-3">
                            Vous pouvez faire évoluer votre DPE actuel. <br/>
                            Choisissez un objectif de DPE. Vous pourrez modifier votre objectif par la suite.
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                    <div className="flex flex-col p-6 md:p-12 ">

                        <div className="flex w-full flex-col justify-center items-center">
                            <div
                                className="flex items-center p-2 space-x-10 justify-center text-sm border-dotted border-2 border-red-500 rounded-lg text-center">
                                <div><img src={dpeImages[classeActuelle]} alt={classeActuelle} className="h-40 m-3"/>
                                </div>
                                <div className="flex flex-col text-left">
                                    <div className="font-bold text-lg mb-3">Votre DPE actuel : {classeActuelle}</div>
                                    Votre actif est considéré comme passoire énergétique.<br/><br/>
                                    Selon l’article de loi climat & résilience, vous ne pourrez plus louer votre actif à
                                    partir
                                    de xxx ni le vendre à partir de xxx
                                </div>
                            </div>
                        </div>

                        <p className="text-lg font-bold mt-12">Choisissez un objectif DPE</p>
                        <p>ou fixez en un personnalisé en dessous des propositions</p>

                        <div className="flex flex-row flex-wrap justify-center mt-12">
                            {planAction &&  planAction
                                .filter((item) => item.typePlan !== "CUSTOM")
                                .map((action, index) => {
                                return <div key={index}
                                            className="flex flex-col justify-between shadow-md border rounded-lg max-w-[350px] p-8 mx-3 mb-4">
                                    <div>
                                        <div className="font-bold text-lg text-center mb-6">{action.typePlan}</div>

                                        <div className="mb-4">
                                            <WObjective level={classeActuelle}
                                                        objective={action.classeEnergetiqueObjectique}/>
                                        </div>


                                        <div className="text-center mb-14">Avec un
                                            DPE {action.classeEnergetiqueObjectique} vous êtes aux normes au delà de
                                            2034
                                            Pour atteindre votre objectif vous devez économiser minimum
                                            <span
                                                className="font-bold block">{action.previsionEconomie} kWhep / An</span>
                                        </div>

                                        <div className="font-bold text-lg">Travaux à prévoir :</div>

                                        {action.travaux.map((trav, index) => {
                                            const iconMap: Record<string, string> = {
                                                "Isolation intérieurs murs exposés": iconProject,
                                                "Isolation extérieure murs exposés": iconProject,
                                                "Pose double vitrage": iconWind,
                                                "Ventillation": iconVent,
                                                "Chauffage": iconChauf,
                                            };

                                            return (
                                                <React.Fragment key={index}>
                                                    <div
                                                        className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                                    <div className="flex space-x-4">
                                                        <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                                             alt="Icon"/>
                                                        <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>

                                    <div>
                                        <div className="font-bold text-lg mt-28">Estimations :</div>

                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                                            className="font-bold"> {action.surfacePerdue} m²</span></p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.economieEnergie}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.budget}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.montantAides}</p>
                                        <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                        <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                                        <p className="text-[#5FBB46] font-medium text-2xl mt-2">{action.resteACharge}</p>

                                        <div className="w-full m-auto flex mt-12">
                                            <WButton variant="secondary" onClick={() => {
                                                handleSelect(action.classeEnergetiqueObjectique);
                                            }} className="w-full">Sélectionner</WButton>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                        {planAction && planAction.filter((item) => item.typePlan === "CUSTOM").length > 0 &&
                            <div>
                                <div className="font-bold text-lg text-left mb-3 mt-6 ">Fixer un objectif personnalisé</div>
                                <WDPESelector  projectId={currentProject.id}
                                               excludeObjectives={[...planAction
                                                   .filter((item) => item.typePlan !== "CUSTOM")
                                                   .map((item) => item.classeEnergetiqueObjectique),
                                                   currentProject.classeEnergetiqueActuel]}

                                               onSelect={(objective) => {
                                                   handleSelect(objective);
                                               }} />

                                <div className="flex flex-col mt-12 justify-start ">
                                    <a href="https://wattless.fr/estimation-des-travaux-de-renovation/" target="_blank"
                                       rel="noreferrer"
                                       className="block text-green-600 font-medium underline mt-3">
                                        Comment estimons-nous les travaux à réaliser ?
                                    </a>

                                    <div>
                                        <WButton variant="primary" onClick={handlePrevious} className="mt-8">Précédent</WButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>

        </>);
};

export default Objective;