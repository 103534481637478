import React from "react";
import dpeA from "../../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../../assets/icons/DPE/DPE G.svg";
import {useQuery} from "react-query";
import {loiClimatResilience} from "../../../api/project";

interface ObjectifInfoProps {
    numericId: number;
    classeActuelle: string;
}

const dpeImages: { [key: string]: string } = {
    A: dpeA,
    B: dpeB,
    C: dpeC,
    D: dpeD,
    E: dpeE,
    F: dpeF,
    G: dpeG,
};

const ObjectifInfo: React.FC<ObjectifInfoProps> = ({numericId, classeActuelle}) => {

    const {data: loiClimatResilienceData} = useQuery(
        ["loiClimatResilience", numericId],
        async () => {
            return (numericId) ? await loiClimatResilience(numericId) : null;
        }
    );

    return <div className="flex w-full flex-col justify-center items-center">
        <div
            className="flex items-center p-2 space-x-10 justify-center text-sm border-dotted border-2 border-red-500 rounded-lg text-center">
            <div><img src={dpeImages[classeActuelle]} alt={classeActuelle} className="h-40 m-3"/>
            </div>
            <div className="flex flex-col text-left">
                <div className="font-bold text-lg mb-3">Votre DPE actuel : {classeActuelle}</div>
                {loiClimatResilienceData?.description}
            </div>
        </div>
    </div>
};

export default ObjectifInfo;