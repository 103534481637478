import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {useNavigate} from "react-router-dom";
import WButton from "../../../../components/Wbutton";
import WInput from "../../../../components/WInput";
import {
    IOfferMoment,
    IPaymentRequest,
    IResponsePayment,
    updateCoupon,
    validateCoupon
} from "../../../../api/payment";
import {useMutation} from "react-query";
import {useDispatch} from "react-redux";
import {updateAccount} from "../../../../redux/slices/accountSlice";
import {FiLogOut} from "react-icons/fi";

interface StripeFormProps {
    plan: IPaymentRequest;
    year: boolean;
    payment: IResponsePayment;
    pack: IOfferMoment;
}

const StripeForm: React.FC<StripeFormProps> = ({plan, year, payment, pack}) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const navigate = useNavigate();
    const [coupon, setCoupon] = useState("");
    const [percent, setPercent] = useState(0);

    const handleSubmitStripe = async (event: React.FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        dispatch(updateAccount({
            nom: plan.nom,
            prenom: plan.prenom,
            adresse: plan.adresse,
            email: plan.email,
            subscriptionID: plan.subscriptionID,
            codePostal: plan.codePostal,
            ville: plan.ville,
            societe: plan.societe,
            siret: plan.siret,
            mobile: plan.mobile,
            secretKey: "123456",
            mensuelle: !year,
            customerIDStripe: payment.customerID,
            subscriptionIDStripe: payment.subscriptionID,
            firstInvoiceIDStripe: payment.firstInvoiceID
        }))

        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/confirm-payment`,
            },
        });

        if (error) {
            console.log('ERROR', error.message);
        } else {
            navigate('/confirm-payment');
        }
    };

    const {mutate} = useMutation(async ({coupon}: { coupon: string }) => {
        const couponRes = coupon ? await validateCoupon(coupon) : null;
        plan.couponID = (couponRes) ? coupon : "";
        await updateCoupon(payment.subscriptionID, plan.couponID);
        setPercent(couponRes ? couponRes.percent : 0);
        setCoupon((couponRes) ? coupon : "");
    });

    const handleAbandonner = () => {
        navigate('/pricing-plans');
    };

    const handleCoupon = () => {
        setCoupon(coupon);
        mutate({coupon});
    }

    return <div
        className="fixed flex items-center justify-center bg-[#899BB4] bg-opacity-75 w-full h-full z-[80] top-0 left-0">

        <div
            className="flex flex-col md:flex-row justify-items-stretch bg-white p-10 rounded w-full m-1 md:m-10 max-w-[1050px]">
            <div className="flex flex-row justify-between items-center mt-3 mr-32 text-left mb-10 w-full h-full">
                <div
                    className="mb-14 text-xl">
                    <div className="text-2xl font-bold py-3">Votre choix :</div>
                    <div className="bg-gray-100 text-base rounded-lg p-1">{pack.libelle} -
                        Abonnement: {year ? 'annuel' : 'mensuel'}</div>

                    <div className="font-medium mt-3 py-3">Abonnement: {year ? 'annuel' : 'mensuel'}</div>

                    {year ?
                        <div className="bg-gray-100 text-base rounded-lg p-1">Prix
                            HTVA {Number(pack.prixAnnuelHT) * (1 - percent / 100)} €</div> :
                        <div className="bg-gray-100 text-base rounded-lg p-1">Prix
                            HTVA {Number(pack.prixMensuelHT) * (1 - percent / 100)} €</div>
                    }

                    <div className="my-3 text-base mb-6">TVA {pack.valeurTVA}%:</div>

                    {year ?
                        <div className="font-medium bg-gray-100 text-3xl rounded-lg p-3">
                            Total à payer: {Number(pack.prixAnnuelTtc) * (1 - percent / 100)} €
                        </div> :
                        <div className="font-medium bg-gray-100 text-3xl rounded-lg p-3">
                            Total à payer: {Number(pack.prixMensuelTtc) * (1 - percent / 100)} €
                        </div>
                    }

                    <div onClick={() => navigate('/pricing-plans')}
                         className="underline cursor-pointer text-base mt-2">changer de solution
                    </div>

                    <div className="flex mt-10">
                        <WInput className="mr-2" name="coupon" value={coupon} placeholder="Coupon Code"
                                onChange={(e) => setCoupon(e.target.value)} />
                        <WButton variant="secondary" onClick={handleCoupon}>Valider</WButton>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full">
                <div
                    onClick={handleAbandonner}
                    className="flex items-center space-x-2 cursor-pointer text-red-500 justify-end mb-8"
                >
                    <FiLogOut className="h-6 w-6"/>
                    <span className="font-normal text-gray-500 hidden md:block">Abandonner</span>
                </div>
                <form onSubmit={handleSubmitStripe}>

                    <PaymentElement/>
                    <WButton variant="secondary" className='mt-6' disabled={!stripe}>Payer</WButton>
                </form>
            </div>
        </div>
    </div>;
};

export default StripeForm;